import { listWarehousesRequest, searchWarehousesRequest } from '../../api';
import { useEffect, useState } from 'react';
import appNotify from '../appNotify';

export default function useWarehouses(init = false, initialParams = {}) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [warehouses, setWarehouses] = useState([]);
  const [next, setNext] = useState(null);
  const [params, setParams] = useState({
    search: null,
    page: null,
    ...initialParams
  });
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [warehouseOptionsLoading, setWarehouseOptionsLoading] = useState(null);
  function listWarehouses() {
    setPageLoading(true);
    listWarehousesRequest(params)
      .then((res) => {
        setWarehouses(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to load warehouses');
      });
  }

  function loadWarehouses() {
    setWarehouseOptionsLoading(true);
    searchWarehousesRequest(params)
      .then((res) => {
        setWarehouseOptions(res.results);
        setWarehouseOptionsLoading(false);
      })
      .catch(() => {
        setWarehouseOptions([]);
      });
  }

  useEffect(() => {
    if (init) listWarehouses();
    else if (params.search && params.search.length > 0) {
      loadWarehouses();
    }
  }, [params]);

  return {
    pageLoading,
    setWarehouses,
    next,
    warehouses,
    count,
    warehouseOptionsLoading,
    listWarehouses,
    setParams,
    warehouseOptions
  };
}
