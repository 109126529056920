import { PERMISSIONS } from './index';

class RoleManager {
  static PERMISSIONS = PERMISSIONS;

  constructor() {
    this.user = null;
    this.is_superuser = false;
  }

  setUser(user) {
    console.log('Coming User: ', user);
    this.user = user;
    console.log('uSER: ', this.user);
    this.is_superuser = this.user.is_superuser;
  }

  has_permission(permission) {
    if (!this.user) return false;
    if (this.is_superuser) return true;
    return this.user.role ? this.user.role.permissions.includes(permission): false;
  }

  has_one_of_permissions(permissions) {
    let hasPermission = false;
    permissions.forEach((permission) => {
      if (this.has_permission(permission)) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }

  getRole() {
    if (this.is_superuser) return 'Super User';
    return this.user.role ? this.user.role.title : 'No Assigned Role';
  }
}

export const roleManager = new RoleManager();
