import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  rate: 0.0,
  initializing: true,
  selectedWarehouse: {},
};

// ==============================|| SLICE - MENU ||============================== //

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setRate(state, action) {
      state.rate = action.payload;
    },
    setInitializing(state, action) {
      state.initializing = action.payload;
    },
    setSelectWarehouse(state, action) {
      state.selectedWarehouse = action.payload;
    }

  }
});

export default app.reducer;

export const { setRate, setInitializing, setSelectWarehouse } = app.actions;
