import { Checkbox, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { deleteGoodRequest } from '../../../utils/api';
import AnimateButton from '../../../ui-component/extended/AnimateButton';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import appNotify from '../../../utils/libs/appNotify';

function ConfirmDeleteGoodModal({ open, onClose, onDelete = () => {}, good }) {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const { t } = useTranslation();
  function deleteGood() {
    setLoading(true);
    deleteGoodRequest(good.id)
      .then(() => {
        onDelete();
        appNotify.success('Successfully deleted good');
      })
      .catch(() => {
        appNotify.error('Failed to delete good');
        setLoading(false);
      });
  }

  function handleClose() {
    onClose();
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle style={{ fontWeight: 'bold', fontSize: 20 }}>Are your sure?</DialogTitle>
      <DialogContent>
        <Typography variant="body1" align="left">
          You are about to delete <span style={{ fontWeight: 'bold' }}>{good.name}</span> , this action is not reversible.
        </Typography>
        <Grid container direction="row" justifyContent="center" alignItems="center" mt={1}>
          <Grid item>
            <FormGroup>
              <FormControlLabel onChange={(e, c) => setChecked(c)} checked={checked} control={<Checkbox />} label={t('common.tick_sure')} />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" mt={0} mg={3}>
          <Grid item xs={3}>
            <AnimateButton>
              <LoadingButton
                disableElevation
                loading={loading}
                disabled={loading || !checked}
                fullWidth
                size="large"
                onClick={() => {
                  deleteGood();
                }}
                variant="contained"
                color="primary"
              >
                {t('common.submit')}
              </LoadingButton>
            </AnimateButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

ConfirmDeleteGoodModal.propTypes = {
  good: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func
};

export default ConfirmDeleteGoodModal;
