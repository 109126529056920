import PropTypes from 'prop-types';
import { Result } from 'antd';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import PageUnderConstruction from '../../../ui-component/PageUnderConstruction';
import { roleManager } from '../../../utils/libs/permissions/manager';

// ===============================|| COMPONENT - SKELETON ||=============================== //

function DefaultResult({ message = null }) {
  const navigate = useNavigate();
  const navigateHome = () => {
    navigate('/', { replace: true });
  };
  return (
    <Result
      status="403"
      title="403"
      subTitle={message ?? "Sorry, you are not authorized to access this page."}
      extra={
        <Button onClick={navigateHome} type="primary">
          Back Home
        </Button>
      }
    />
  );
}

DefaultResult.propTypes = {
  message: PropTypes.any
}

function PermissionProvider({ children, message = null, permission, showUnAuthorizedPage = false, withOtherDeps = () => true, showUc = false }) {
  console.log('With other deps: ', withOtherDeps());
  if (!roleManager.has_permission(permission) || !withOtherDeps()) {
    if (showUnAuthorizedPage) {
      return showUc ? <PageUnderConstruction /> : <DefaultResult message={message} />;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  return children;
}

PermissionProvider.propTypes = {
  children: PropTypes.node,
  showUnAuthorizedPage: PropTypes.bool,
  withOtherDeps: PropTypes.func,
  showUc: PropTypes.bool,
  permission: PropTypes.any,
  message: PropTypes.any
};

export default PermissionProvider;

export function ServiceActiveProvider({ children, active = true }) {
  const navigate = useNavigate();
  const navigateHome = () => {
    navigate('/', { replace: true });
  };
  return active ? (
    children
  ) : (
    <Result
      status="info"
      title="Service unavailable!"
      subTitle="Sorry, this service is not available at the moment, try again later."
      extra={
        <Button onClick={navigateHome} type="primary">
          Back Home
        </Button>
      }
    />
  );
}

ServiceActiveProvider.propTypes = {
  children: PropTypes.node
};
