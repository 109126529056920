import React, { useState } from 'react';
import { Badge, Descriptions } from 'antd';
import { Divider, Grid, IconButton } from '@mui/material';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { LoadingButton } from '@mui/lab';
import AnimateButton from '../../../ui-component/extended/AnimateButton';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../ui-component/cards/MainCard';
import useDiscounts from '../../../utils/libs/hooks/use-discounts';
import { currencyFormatter, renderDateTime } from '../../../utils/libs';

const items = [
  {
    key: '1',
    label: 'Created At',
    span: 1,
    children: '2018-04-24 18:00:00'
  },
  {
    key: '2',
    label: 'Issued By',
    span: 2,
    children: 'Kelvin Remmy'
  },
  {
    key: '3',
    label: 'Discount',
    span: 3,
    children: '$80.00'
  },

  {
    key: '4',
    label: 'Discount Details',
    children: 'Discount details for this invoice'
  }
];
function DiscountDetails({ invoice }) {
  const [add, setAdd] = useState(false);
  const { t } = useTranslation();
  const { discounts, pageLoading } = useDiscounts(true, { invoiceID: invoice.id });

  function formatToItems(disc) {
    return [
      {
        key: '1',
        label: 'Created At',
        span: 1,
        children: renderDateTime(disc.created_at)
      },
      {
        key: '2',
        label: 'Issued By',
        span: 2,
        children: disc.created_by_name
      },
      {
        key: '3',
        label: 'Discount',
        span: 3,
        children: currencyFormatter((parseFloat(disc.percentage) / 100) * parseFloat(invoice.amount), 'USD')
      },

      {
        key: '4',
        label: 'Discount Details',
        children: disc.details
      }
    ];
  }
  return (
    <MainCard
      title={
        <Grid container spacing={3} alignItems="center">
          <Grid item>List of Discounts</Grid>
        </Grid>
      }
    >
      {discounts.map((discount, idx) => (
        <>
          <Grid container alignItems="center" justifyContent="space-between" mb={4} mt={1}>
            <Grid item xs={11}>
              <Descriptions
                title={`Dicsount on ${new Date(discount.created_at).toLocaleDateString('en-CA')}`}
                layout="vertical"
                bordered
                items={formatToItems(discount)}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton>
                <IconTrash />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
        </>
      ))}
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <AnimateButton>
            <LoadingButton disableElevations fullWidth onClick={() => {}} size="large" variant="text" endIcon={<IconPlus />}>
              {t('common.add_new')}
            </LoadingButton>
          </AnimateButton>
        </Grid>
      </Grid>
    </MainCard>
  );
}
export default DiscountDetails;
