import { listSuppliersRequest, searchSuppliersRequest } from '../../api';
import { useEffect, useState } from 'react';
import appNotify from '../appNotify';

export default function useSuppliers(init = false, initialParams = {}) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [next, setNext] = useState(null);
  const [params, setParams] = useState({
    search: null,
    page: null,
    ...initialParams
  });
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [suppliersOptionsLoading, setSuppliersOptionsLoading] = useState(null);
  function listSuppliers() {
    setPageLoading(true);
    listSuppliersRequest(params)
      .then((res) => {
        setSuppliers(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch(() => {
        appNotify.error('Failed to load suppliers, please try again later!');
      });
  }

  function loadSuppliers() {
    setSuppliersOptionsLoading(true);
    searchSuppliersRequest(params)
      .then((res) => {
        setSupplierOptions(res.results);
        setSuppliersOptionsLoading(false);
      })
      .catch(() => {
        setSupplierOptions([]);
      });
  }

  useEffect(() => {
    if (init) listSuppliers();
    else if (params.search && params.search.length > 0) {
      loadSuppliers();
    }
  }, [params]);

  return { pageLoading, next, suppliers, count, suppliersOptionsLoading, listSuppliers, setParams, supplierOptions };
}
