import Invoice from './components/Invoice';
import { getInvoiceByIDRequest } from '../../utils/api';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function ViewInvoice({ invoiceID }) {
  const [bill, setBill] = useState(null);
  function getInvoice() {
    getInvoiceByIDRequest(invoiceID)
      .then((res) => {
        console.log('Res: ', res.bill);
        setBill(res.bill);
      })
      .catch((err) => {
        console.log('Err: ', err);
      });
  }

  useEffect(() => {
    getInvoice();
  }, []);
  if (bill !== null) {
    return <Invoice width="100%" bill={bill} />;
  }
  return <p>Loading</p>;
}

ViewInvoice.propTypes = {
  invoiceID: PropTypes.any
};

export default ViewInvoice;
