import {
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import { CONTAINER_STATUS } from '../../utils/libs/constants';
import { LoadingButton } from '@mui/lab';
import AnimateButton from '../../ui-component/extended/AnimateButton';
import { editContainerStatusRequest } from '../../utils/api';
import appNotify from '../../utils/libs/appNotify';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function EditContainerStatusModal({ open, onClose, container }) {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [send, setSend] = useState(true);
  const [status, setStatus] = useState();
  const { t } = useTranslation();

  function handleClose() {
    onClose();
  }
  function editContainerStatus() {
    setLoading(true);
    editContainerStatusRequest(`${container.id}?status=${status}&send=${send.toString()}`)
      .then((res) => {
        console.log('Res: ', res);
        appNotify.success('Container status edited successfully');
        onClose(status);
        setLoading(false);
      })
      .catch((err) => {
        console.log('Error: ', err);
        appNotify.error('Failed to edit container status');
        setLoading(false);
      });
  }
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      {container !== null && (
        <>
          <DialogTitle style={{ fontWeight: 'bold' }}>
            <Typography variant="h3" gutterBottom>
              Editing | Cont No#{container.container_no} | Status
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <InputLabel htmlFor="bootstrap">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-disabled-label"
                  id="demo-simple-select-disabled"
                  value={status}
                  placeholder="Select Status"
                  onChange={(e) => {
                    console.log('Value: ', e.target.value);
                    setStatus(e.target.value);
                  }}
                  style={{
                    height: 50,
                    borderRadius: 30,
                    zIndex: 10000
                  }}
                  fullWidth
                  // error={Boolean(touched.unit && errors.unit)}
                >
                  <MenuItem value={CONTAINER_STATUS.SHIPPED}>Shipped</MenuItem>
                  {container.shipping_status >= CONTAINER_STATUS.SHIPPED && <MenuItem value={CONTAINER_STATUS.PORT}>Port</MenuItem>}
                  {container.shipping_status >= CONTAINER_STATUS.PORT && <MenuItem value={CONTAINER_STATUS.ARRIVED}>Arrived</MenuItem>}
                </Select>
              </Grid>
              <Grid item mb={4} xs={12}>
                <FormGroup>
                  <FormControlLabel
                    onChange={(e, c) => setSend(c)}
                    checked={send}
                    control={<Checkbox />}
                    label="Send Notification to customers"
                  />
                </FormGroup>
              </Grid>
              <Grid item mb={2} xs={12}>
                <FormGroup>
                  <FormControlLabel
                    onChange={(e, c) => setChecked(c)}
                    checked={checked}
                    control={<Checkbox />}
                    label="Are you sure, this action is not reversible."
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <DialogActions>
              <AnimateButton>
                <LoadingButton
                  disableElevations
                  loading={loading}
                  disabled={loading || !checked}
                  fullWidth
                  size="large"
                  onClick={editContainerStatus}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </LoadingButton>
              </AnimateButton>
            </DialogActions>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

EditContainerStatusModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  container: PropTypes.any
};

export default EditContainerStatusModal;
