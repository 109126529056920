import { getConsignmentByIDRequest, listConsignmentsRequest, searchConsignmentsRequest } from '../../api';
import { useEffect, useState } from 'react';
import appNotify from '../appNotify';

export default function useConsignments(init = false, initialParams = {}) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [consignments, setConsignments] = useState([]);
  const [consignmentDetails, setConsignmentDetails] = useState(null);
  const [next, setNext] = useState(null);
  const [consignmentOptions, setConsignmentOptions] = useState([]);
  const [consignmentOptionsLoading, setConsignmentOptionsLoading] = useState(false);
  const [params, setParams] = useState({
    page: null,
    search: null,
    c: null,
    ...initialParams
  });
  function listConsignments(params) {
    setPageLoading(true);
    listConsignmentsRequest(params)
      .then((res) => {
        setConsignments(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to load consignments');
        setConsignments([]);
      });
  }

  function getConsignmentByID(consID) {
    setPageLoading(true);
    getConsignmentByIDRequest(consID)
      .then((res) => {
        setConsignmentDetails(res.consignment);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to load consignments');
        setConsignmentDetails(null);
      });
  }

  function loadConsignments(params) {
    setConsignmentOptionsLoading(true);
    searchConsignmentsRequest(params)
      .then((res) => {
        console.log('Res: ', res);
        setConsignmentOptions(res.results);
        setConsignmentOptionsLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to search consignments');
        setConsignmentOptions([]);
      });
  }

  useEffect(() => {
    if (init) listConsignments(params);
    else if (params.search && params.search.length > 0) {
      loadConsignments(params);
    }
  }, [params]);

  return {
    pageLoading,
    next,
    consignments,
    count,
    params,
    listConsignments,
    consignmentOptionsLoading,
    setParams,
    consignmentOptions,
    getConsignmentByID,
    consignmentDetails
  };
}
