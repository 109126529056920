import { snackbarRef } from '../../App';

class AppNotify {
  static success(message) {
    if (snackbarRef) {
      snackbarRef.enqueueSnackbar(message, { variant: 'success' });
    }
  }

  static error(message) {
    if (snackbarRef) {
      snackbarRef.enqueueSnackbar(message, { variant: 'error' });
    }
  }

  static warning(message) {
    if (snackbarRef) {
      snackbarRef.enqueueSnackbar(message, { variant: 'warning' });
    }
  }
}

export default AppNotify;
