import { lazy } from 'react';
import Loadable from '../ui-component/Loadable';
import MinimalLayout from '../layout/MinimalLayout';

const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/reset-password/:user_id',
      element: <ResetPassword />
    }
    // {
    //     path: '/register',
    //     element: <AuthRegister3 />
    // },
    // {
    //     path: '/stepers',
    //     element: <Stepers />
    // }
  ]
};

export default AuthenticationRoutes;
