import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { IconHome, IconList, IconPlus, IconReport, IconShieldPlus, IconUserPlus } from '@tabler/icons-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PERMISSIONS } from '../utils/libs/permissions';
import PermissionProvider from '../pages/users-roles-manager/components/PermissionHandler';
import { roleManager } from '../utils/libs/permissions/manager';

export default function TabsWrapperNav({ tabs = 'goods' }) {
  const [value, setValue] = React.useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const navs = {
    goods: [
      {
        label: t('goods.list_of_goods'),
        value: 'list',
        permission: PERMISSIONS.GOODS_READ,
        icon: <IconList />
      },
      {
        label: t('goods.create'),
        value: 'add',
        permission: PERMISSIONS.GOODS_CREATE,
        icon: <IconPlus />
      }
    ],
    consignments: [
      {
        label: t('consignments.list_of_consignments'),
        value: 'list',
        permission: PERMISSIONS.CONSIGNMENTS_READ,
        icon: <IconList />
      },
      {
        label: t('consignments.create'),
        value: 'add',
        permission: PERMISSIONS.CONSIGNMENTS_CREATE,
        icon: <IconPlus />
      }
    ],
    invoices: [
      {
        label: t('invoices.list_of_invoices'),
        value: 'list',
        icon: <IconList />
      },
      {
        label: t('invoices.create'),
        value: 'add',
        icon: <IconPlus />
      },
      {
        label: 'Invoice Payments',
        value: 'payments',
        icon: <IconList />
      },
      {
        label: 'Reports',
        value: 'reports',
        icon: <IconReport />
      }
    ],
    customers: [
      {
        label: t('customers.list_of_customers'),
        value: 'list',
        icon: <IconList />
      },
      {
        label: t('customers.create'),
        value: 'add',
        icon: <IconPlus />
      }
    ],
    sms: [
      {
        label: t('sms.list_of_sms'),
        value: 'list',
        icon: <IconList />
      },
      {
        label: t('sms.add'),
        value: 'add',
        icon: <IconPlus />
      }
    ],
    containers: [
      {
        label: t('containers.list_of_containers'),
        value: 'list',
        icon: <IconList />
      },
      {
        label: t('containers.create'),
        value: 'add',
        icon: <IconPlus />
      }
    ],
    categories: [
      {
        label: t('price_categories.list_of_price_categories'),
        value: 'list',
        icon: <IconList />
      },
      {
        label: t('price_categories.create'),
        value: 'add',
        icon: <IconPlus />
      }
    ],
    bls: [
      {
        label: t('bls.list_of_bls'),
        value: 'list',
        icon: <IconList />
      },
      {
        label: t('bls.add'),
        value: 'create',
        icon: <IconPlus />
      }
    ],
    dispatches: [
      {
        label: t('dispatches.list'),
        value: 'list',
        icon: <IconList />
      },
      {
        label: t('dispatches.authorize'),
        value: 'authorize',
        icon: <IconPlus />
      }
    ],
    users: [
      {
        label: 'List of Users',
        value: 'list',
        icon: <IconList />
      },
      {
        label: 'Add User',
        value: 'add',
        icon: <IconUserPlus />
      },
      {
        label: 'List of Roles',
        value: 'list-roles',
        icon: <IconList />
      },
      {
        label: 'Add Role',
        value: 'add-role',
        icon: <IconShieldPlus />
      }
    ]
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };
  useEffect(() => {
    const pathArray = location.pathname.split('/');
    const lastPathValue = pathArray[pathArray.length - 1];
    setValue(lastPathValue);
  }, [location]);

  return (
    <Grid container spacing={3}>
      <Grid item>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab value="/" icon={<IconHome />} iconPosition="start" label={t('common.back_home')} />
            {navs[tabs]
              .filter((i) => (i.permission ? roleManager.has_permission(i.permission) : true))
              .map((tab, idx) => (
                <Tab key={idx} value={tab.value} icon={tab.icon} iconPosition="start" label={tab.label} />
              ))}
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Outlet />
      </Grid>
    </Grid>
  );
}

TabsWrapperNav.propTypes = {
  tabs: PropTypes.string
};
