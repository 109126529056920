import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { editCustomerRequest, getCustomerByIDRequest } from '../../../../utils/api';
import appNotify from '../../../../utils/libs/appNotify';
import { formatPhoneNumber } from '../../../../utils/libs';
import MainCard from '../../../../ui-component/cards/MainCard';
import { Box } from '@mui/material';
import CustomerForm from '../../../customers/components/CustomerForm';
import PropTypes from 'prop-types';
import UserForm from '../../components/UserForm';

function UserDetails({ user, onClose }) {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  const { t } = useTranslation();

  function editCustomer(values) {
    setLoading(true);
    console.log('Values: ', values);
    editCustomerRequest(user, values)
      .then((res) => {
        appNotify.success('Successfully edited Customer details');
        setLoading(false);
        onClose(true);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to edit customer');
        setLoading(false);
      });
  }

  useEffect(() => {
    setInitialValues({
      full_name: user.full_name,
      email: user.email,
      cellphone: user.cellphone
    });
  }, []);

  return (
    <MainCard title={t('users.edit_profile')} content={false} border={false} boxShadow>
      <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 } }}>
        {initialValues && <UserForm isEdit initialValues={initialValues} loading={loading} onSubmit={(values) => editCustomer(values)} />}
      </Box>
    </MainCard>
  );
}

UserDetails.propTypes = {
  user: PropTypes.any,
  onClose: PropTypes.func
};

export default UserDetails;
