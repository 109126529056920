import { createSlice } from '@reduxjs/toolkit';
import * as actionTypes from 'store/reducers/actions';
import config from '../../config';

// initial state
export const initialState = {
  isOpen: [], // for active default menu
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true
};

// =============================|| SLICE - MENU ||============================== //

const customization = createSlice({
  name: 'customization',
  initialState,
  reducers: {
    customizationReducer(state, action) {
      let id;
      if (action.type === actionTypes.MENU_OPEN) {
        id = action.id;
        state.isOpen = id;
      } else if (action.type === actionTypes.SET_MENU) {
        state.opened = action.open;
      } else if (action.type === actionTypes.SET_FONT_FAMILY) {
        state.fontFamily = action.fontFamily;
      } else if (action.type === actionTypes.SET_BORDER_RADIUS) {
        state.borderRadius = action.borderRadius;
      }
    }
  }
});

export default customization.reducer;

export const { customizationReducer } = customization.actions;
