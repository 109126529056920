import PropTypes from 'prop-types';
import { DownloadingOutlined } from '@mui/icons-material';
import { Fab } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies

export default function ExportToPDF({ children }) {
  const printDocument = () => {
    window.print();
    // const input = document.getElementsByClassName('divToPrint');
    // console.log(input);
    // if (input.length > 0) {
    //   const opt = {
    //     margin: 1,
    //     filename: 'myfile.pdf',
    //     image: { type: 'jpeg', quality: 0.98 },
    //     html2canvas: { scale: 2 },
    //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    //   };
    //
    //   html2pdf().from(input[0]).set(opt).save();
    // }
  };

  return (
    <div>
      {children}
      <Fab style={{ position: 'absolute', bottom: 16, right: 16 }} color="primary" aria-label="add" onClick={printDocument}>
        <DownloadingOutlined />
      </Fab>
    </div>
  );
}

ExportToPDF.propTypes = {
  children: PropTypes.node
};
