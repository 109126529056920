import * as Yup from 'yup';
import { Formik } from 'formik';
import { FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AnimateButton from '../../../ui-component/extended/AnimateButton';
import AmountView, { NumberFormatter } from '../../../ui-component';
import { forwardRef, useState } from 'react';

function PerformDiscountForm({ loading, onSubmit, currentAmount = 0 }) {
  const [discType, setDiscType] = useState('%');
  const [amount, setAmount] = useState(0);
  const [newAmount, setNewAmount] = useState(currentAmount);

  // eslint-disable-next-line react/no-unstable-nested-components
  const NumericCustom = forwardRef((props, ref) => (
    <NumberFormatter
      {...props}
      maxFloatValue={discType === '%' ? 100 : currentAmount}
      prefix={discType === '$' ? 'USD' : ''}
      sufix={discType === '%' ? '%' : ''}
    />
  ));

  // useEffect(() => {
  //   setNewAmount((prevState) => {
  //     if (discType === '%') {
  //       return (amount / 100) * currentAmount;
  //     }
  //     return currentAmount;
  //   });
  // }, [discType, amount]);

  const deduceNewAmount = (_amount) => {
    if (discType === '%' && _amount) {
      return currentAmount - (parseFloat(_amount) / 100) * currentAmount;
    }
    if (discType === '$' && _amount) {
      return currentAmount - parseFloat(_amount);
    }
    return currentAmount;
  };

  function deducePercentage(_amount) {
    if (discType === '%' && _amount) {
      return _amount;
    }
    if (discType === '$' && _amount) {
      return ((currentAmount - parseFloat(_amount)) / currentAmount) * 100;
    }
    return currentAmount;
  }

  return (
    <Stack spacing={2}>
      <Formik
        initialValues={{
          details: '',
          amount: ''
        }}
        validationSchema={Yup.object().shape({
          details: Yup.string().max(255).required('Discount details is required'),
          amount: Yup.string().max(255).required('Price Amount is required')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          onSubmit({
            details: values.details,
            percentage: deducePercentage(values.amount)
          });
        }}
      >
        {({ setFieldValue, errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="bootstrap">Details</InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="text"
                    value={values.details}
                    name="details"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Provide some details to this discount"
                    fullWidth
                    multiline
                    rows={5}
                    error={Boolean(touched.details && errors.details)}
                  />
                  {touched.details && errors.details && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.details}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="bootstrap">Amount (Invoice Amount: {currentAmount})</InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="text"
                    value={values.amount}
                    name="amount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={discType === '$' ? 'eg. USD 4,050' : 'eg. 12%'}
                    inputComponent={NumericCustom}
                    fullWidth
                    maxValue={200}
                    style={{ padding: 0 }}
                    error={Boolean(touched.price && errors.price)}
                    startAdornment={
                      <InputAdornment style={{ padding: 0 }} position="start">
                        {' '}
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={discType}
                          onChange={(e) => {
                            console.log('Change: ', e.target.value);
                            setFieldValue('amount', 0);
                            setDiscType(e.target.value);
                          }}
                        >
                          <MenuItem value="%">%</MenuItem>
                          <MenuItem value="$">$</MenuItem>
                        </Select>
                      </InputAdornment>
                    }
                  />
                  {touched.price && errors.price && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.price}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <AmountView title="NEW AMOUNT" amount={deduceNewAmount(values.amount)} />
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="center" mg={3}>
              <Grid item xs={6}>
                <AnimateButton>
                  <LoadingButton
                    disableElevation
                    loading={loading}
                    disabled={loading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </LoadingButton>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Stack>
  );
}

export default PerformDiscountForm;
