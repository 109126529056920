import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';

function ConfirmationDialog({ open, setOpen, onConfirm, description, onClose = () => {} }) {
  const [checked, setChecked] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const handleClose = () => {
    if (submitted) return;
    setOpen(false);
    onClose();
    setChecked(false);
  };

  const handleConfirm = async () => {
    setSubmitted(true);
    const success = await onConfirm();
    setSubmitted(false);

    if (success) handleClose();
  };
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle>Are you sure</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Stack spacing={2}>
            <div>{description}, tick the box below if you are sure of this action</div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(event) => setChecked(event.target.checked)}
                  name="checked"
                  color="primary"
                  size="small"
                />
              }
              label={<Typography variant="h6">Yes, I am sure</Typography>}
            />
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={submitted} color="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button disabled={!checked || submitted} onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onConfirm: PropTypes.func,
  description: PropTypes.any,
  onClose: PropTypes.any
};

export default ConfirmationDialog;
