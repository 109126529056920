import { lazy } from 'react';
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import TabsWrapperNav from '../layout/TabsWrapperNav';
import PermissionProvider from '../pages/users-roles-manager/components/PermissionHandler';

// ==============================|| MAIN ROUTING ||============================== //
const Home = Loadable(lazy(() => import('../pages/home')));

// ==============================|| GOODS ROUTING ||============================== //
const ListGoods = Loadable(lazy(() => import('../pages/goods/ListGoods')));
const AddGood = Loadable(lazy(() => import('../pages/goods/AddGood')));

// ==============================|| CONSIGNMENTS ROUTING ||============================== //
const ListConsignments = Loadable(lazy(() => import('../pages/consignments/ListConsignments')));
const AddConsignment = Loadable(lazy(() => import('../pages/consignments/AddConsignment')));

// ==============================|| CUSTOMERS ROUTING ||============================== //
const ListCustomers = Loadable(lazy(() => import('../pages/customers/ListCustomers')));
const AddCustomer = Loadable(lazy(() => import('../pages/customers/AddCustomer')));

// ==============================|| CONTAINERS ROUTING ||============================== //
const ListContainers = Loadable(lazy(() => import('../pages/containers/ListContainers')));
const AddContainer = Loadable(lazy(() => import('../pages/containers/AddContainer')));

// ==============================|| SUPPLIERS ROUTING ||============================== //
const ListSuppliers = Loadable(lazy(() => import('../pages/suppliers/ListSuppliers')));
const AddSupplier = Loadable(lazy(() => import('../pages/suppliers/AddSupplier')));

// ==============================|| PRICE CATEGORIES ROUTING ||============================== //
const ListPriceCategories = Loadable(lazy(() => import('../pages/price-categories/ListPriceCategories')));
const AddPriceCategory = Loadable(lazy(() => import('../pages/price-categories/AddPriceCategory')));

// ==============================|| PRICE CATEGORIES ROUTING ||============================== //
const CreateShipment = Loadable(lazy(() => import('../pages/shipments/CreateShipment')));
// const AddPriceCategory = Loadable(lazy(() => import('../pages/price-categories/AddPriceCategory')));

// ==============================|| WAREHOUSES CATEGORIES ROUTING ||============================== //
const ListWarehouses = Loadable(lazy(() => import('../pages/warehouses/ListWarehouses')));

// ==============================|| WAREHOUSES CATEGORIES ROUTING ||============================== //
const ListSMS = Loadable(lazy(() => import('../pages/sms/ListSMS')));
const AddSMS = Loadable(lazy(() => import('../pages/sms/AddSMS')));

// ==============================|| WAREHOUSES CATEGORIES ROUTING ||============================== //
const ListInvoices = Loadable(lazy(() => import('../pages/invoices/ListInvoices')));
const AddInvoice = Loadable(lazy(() => import('../pages/invoices/AddInvoice')));
const ViewInvoice = Loadable(lazy(() => import('../pages/invoices/ViewInvoice')));
const InvoicePayments = Loadable(lazy(() => import('../pages/invoices/InvoicePayments')));
const GenerateInvoiceReports = Loadable(lazy(() => import('../pages/invoices/reports/generate')));

// ==============================|| WAREHOUSES CATEGORIES ROUTING ||============================== //
const ListDispatches = Loadable(lazy(() => import('../pages/dispatches/ListDispatches')));
const AuthorizeDispatch = Loadable(lazy(() => import('../pages/dispatches/AuthorizeDispatch')));
const DeliveryNote = Loadable(lazy(() => import('../pages/dispatches/components/delivery-note')));

// ==============================|| WAREHOUSES CATEGORIES ROUTING ||============================== //
const ListUsers = Loadable(lazy(() => import('../pages/users-roles-manager/users/ListUsers')));
const AddUser = Loadable(lazy(() => import('../pages/users-roles-manager/users/AddUser')));
const AddRole = Loadable(lazy(() => import('../pages/users-roles-manager/roles/AddRole')));
const ListRoles = Loadable(lazy(() => import('../pages/users-roles-manager/roles/ListRoles')));

// ==============================|| BILL OF LADING ROUTING ||============================== //
// const AddBillOfLading = Loadable(lazy(() => import('../pages/bill-of-ladings/AddBillOfLading')));
// const ListBillOfLadings = Loadable(lazy(() => import('../pages/bill-of-ladings/ListBillOfLadings')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Home />
    },
    {
      path: 'goods',
      element: <TabsWrapperNav tabs="goods" />,
      children: [
        {
          path: 'list',
          element: <ListGoods />
        },
        {
          path: 'add',
          element: <AddGood />
        }
      ]
    },
    {
      path: 'consignments',
      element: <TabsWrapperNav tabs="consignments" />,
      children: [
        {
          path: 'list',
          element: <ListConsignments />
        },
        {
          path: 'add',
          element: <AddConsignment />
        }
      ]
    },
    {
      path: 'customers',
      element: <TabsWrapperNav tabs="customers" />,
      children: [
        {
          path: 'list',
          element: <ListCustomers />
        },
        {
          path: 'add',
          element: <AddCustomer />
        }
      ]
    },
    {
      path: 'containers',
      element: <TabsWrapperNav tabs="containers" />,
      children: [
        {
          path: 'list',
          element: <ListContainers />
        },
        {
          path: 'add',
          element: <AddContainer />
        }
      ]
    },
    {
      path: 'suppliers',
      children: [
        {
          path: 'list',
          element: <ListSuppliers />
        },
        {
          path: 'add',
          element: <AddSupplier />
        }
      ]
    },
    {
      path: 'price-categories',
      element: <TabsWrapperNav tabs="categories" />,
      children: [
        {
          path: 'list',
          element: <ListPriceCategories />
        },
        {
          path: 'add',
          element: <AddPriceCategory />
        }
      ]
    },
    {
      path: 'shipments',
      children: [
        // {
        //   path: 'list',
        //   element: <ListPriceCategories />
        // },
        {
          path: 'create',
          element: <CreateShipment />
        }
      ]
    },
    {
      path: 'warehouses',
      children: [
        {
          path: 'list',
          element: <ListWarehouses />
        }
      ]
    },
    {
      path: 'sms',
      element: <TabsWrapperNav tabs="sms" />,
      children: [
        {
          path: 'list',
          element: <ListSMS />
        },
        {
          path: 'add',
          element: <AddSMS />
        }
      ]
    },
    {
      path: 'invoices',
      element: <TabsWrapperNav tabs="invoices" />,
      children: [
        {
          path: 'list',
          element: <ListInvoices />
        },
        {
          path: 'add',
          element: <AddInvoice />
        },
        {
          path: 'view',
          element: <ViewInvoice />
        },
        {
          path: 'payments',
          element: <InvoicePayments />
        },
        {
          path: 'reports',
          element: <GenerateInvoiceReports />
        }
      ]
    },
    {
      path: 'dispatches',
      element: <TabsWrapperNav tabs="dispatches" />,
      children: [
        {
          path: 'list',
          element: <ListDispatches />
        },
        {
          path: 'authorize',
          element: <AuthorizeDispatch />
        },
        {
          path: 'delivery-note',
          element: <DeliveryNote />
        }
      ]
    },
    {
      path: 'bls',
      element: <TabsWrapperNav tabs="bls" />,
      children: [
        {
          path: 'list',
          element: <PermissionProvider showUnAuthorizedPage showUc withOtherDeps={() => false} />

        },
        {
          path: 'create',
          element: <PermissionProvider showUnAuthorizedPage showUc withOtherDeps={() => false} />
        }
      ]
    },
    {
      path: 'users',
      element: <TabsWrapperNav tabs="users" />,
      children: [
        {
          path: 'list',
          element: <ListUsers />
        },
        {
          path: 'add',
          element: <AddUser />
        },
        {
          path: 'add-role',
          element: <AddRole />
        },
        {
          path: 'list-roles',
          element: <ListRoles />
        }
      ]
    }
  ]
};

export default MainRoutes;
