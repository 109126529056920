// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import detector from 'i18next-browser-languagedetector';
// eslint-disable-next-line import/no-extraneous-dependencies
import { initReactI18next } from 'react-i18next';
import translationEN from './en/translation.json';
import translationZH from './zh/translation.json';
import { getLanguageCode, useLanguageCode } from '../libs/hooks';
import { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import Config from '../Config';
import PropTypes from 'prop-types';

const LANGUAGE_RESOURCES = {
  en: {
    translation: translationEN
  },
  zh: {
    translation: translationZH
  }
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: LANGUAGE_RESOURCES,
    fallbackLng: Config.fallbackLanguage,
    interpolation: {
      escapeValue: false
    }
  });

i18n.on('languageChanged', () => {
  const lang = getLanguageCode(i18n.languages);
  // updateUserLanguage(lang);
});

function diffLang(lang1, lang2, prefix = '') {
  Object.entries(lang1).forEach(([key, value]) => {
    if (typeof lang2[key] === 'undefined') {
      console.log(prefix + key);
    } else if (typeof value === 'object') {
      diffLang(value, lang2[key], `${prefix}${key}.`);
    }
  });
}

export default function Locales({ children }) {
  const languageCode = useLanguageCode();

  diffLang(translationEN, translationZH);

  useEffect(() => {
    moment.updateLocale(languageCode, {
      calendar: LANGUAGE_RESOURCES[languageCode].translation.common.calendarFormat
    });
  }, [languageCode]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

Locales.propTypes = {
  children: PropTypes.node
};
