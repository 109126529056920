import { FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import useRoles from '../../../utils/libs/hooks/use-roles';
import PropTypes from 'prop-types';
import AnimateButton from '../../../ui-component/extended/AnimateButton';

function UserForm({ onSubmitForm, loading, initialValues = null, isEdit = false }) {
  const { roles } = useRoles(true);
  const [countryCode, setCountryConde] = useState('255');
  console.log('Initial Values', initialValues);

  return (
    <Formik
      initialValues={
        initialValues === null
          ? {
              full_name: '',
              cellphone: '',
              email: '',
              role: ''
            }
          : initialValues
      }
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        full_name: Yup.string().max(255).required('Full Name is required'),
        role: Yup.string().max(255).required('Role is required'),
        cellphone: Yup.string().max(9).min(9).required('Phone Number is required')
      })}
      onSubmit={async (values) => {
        onSubmitForm({
          ...values,
          cellphone: `255${values.cellphone}`
        });
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">Full Name</InputLabel>
                <OutlinedInput
                  id="email-login"
                  type="text"
                  value={values.full_name}
                  name="full_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="eg. John Doe"
                  fullWidth
                  error={Boolean(touched.full_name && errors.full_name)}
                />
                {touched.full_name && errors.full_name && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.full_name}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">Email Address</InputLabel>
                <OutlinedInput
                  id="email-login"
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="eg. example@gmail.com"
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                />
                {touched.email && errors.email && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.email}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">Phone Number</InputLabel>
                <OutlinedInput
                  id="email-login"
                  type="number"
                  value={values.cellphone}
                  name="cellphone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="eg. 7111..."
                  fullWidth
                  style={{ padding: 0 }}
                  startAdornment={
                    <InputAdornment style={{ padding: 0 }} position="start">
                      {' '}
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={countryCode}
                        onChange={() => setCountryConde('255')}
                      >
                        <MenuItem value="255">+255</MenuItem>
                      </Select>
                    </InputAdornment>
                  }
                  error={Boolean(touched.cellphone && errors.cellphone)}
                />
                {touched.cellphone && errors.cellphone && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.cellphone}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            {!isEdit ? (
              <>
                <Grid item xs={12} md={6} lg={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="bootstrap">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-disabled-label"
                      id="demo-simple-select-disabled"
                      value={values.role}
                      onChange={(e) => {
                        setFieldValue('role', e.target.value).then();
                      }}
                      error={Boolean(touched.role && errors.role)}
                    >
                      {roles.map((val, idx) => (
                        <MenuItem key={idx} value={val.id}>
                          {val.title}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.role && (
                      <Grid item xs={12}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Grid>
                    )}
                  </Stack>
                </Grid>
                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}
              </>
            ) : (
              <>
                <Grid item xs={12} md={6} lg={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="bootstrap">Role</InputLabel>
                    <OutlinedInput
                      id="role"
                      type="text"
                      value="Admin"
                      name="role"
                      disabled
                      // onBlur={handleBlur}
                      // onChange={handleChange}
                      // placeholder="eg. Confirm New Password"
                      fullWidth
                      error={Boolean(touched.role && errors.role)}
                    />
                    {touched.confirm_password && errors.confirm_password && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {errors.confirm_password}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="bootstrap">Current Password</InputLabel>
                    <OutlinedInput
                      id="password"
                      type="password"
                      // value={values.full_name}
                      name="current_password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="eg. Enter Current Password"
                      fullWidth
                      error={Boolean(touched.current_password && errors.current_password)}
                    />
                    {touched.current_password && errors.current_password && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {errors.current_password}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="bootstrap">New Password</InputLabel>
                    <OutlinedInput
                      id="new_password"
                      type="password"
                      // value={values.full_name}
                      name="new_password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="eg. Enter New Password"
                      fullWidth
                      error={Boolean(touched.new_password && errors.new_password)}
                    />
                    {touched.new_password && errors.new_password && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {errors.new_password}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center" mg={3}>
            <Grid item xs={6}>
              <AnimateButton>
                <LoadingButton
                  disableElevation
                  disabled={loading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={loading}
                >
                  Submit
                </LoadingButton>
              </AnimateButton>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

UserForm.propTypes = {
  onSubmitForm: PropTypes.func,
  initialValues: PropTypes.any,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool
};

export default UserForm;
