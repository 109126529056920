import { listPriceCategoriesRequest, searchPriceCategoriesRequest } from '../../api';
import { useEffect, useState } from 'react';

export default function usePriceCategories(init = false, initialParams = {}) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [priceCategories, setPriceCategories] = useState([]);
  const [next, setNext] = useState(null);
  const [priceCategoriesOptions, setPriceCategoriesOptions] = useState([]);
  const [priceCategoriesOptionsLoading, setPriceCategoriesOptionsLoading] = useState(false);
  const [params, setParams] = useState({
    page: null,
    search: null,
    ...initialParams
  });
  function listPriceCategories() {
    setPageLoading(true);
    listPriceCategoriesRequest(params)
      .then((res) => {
        setPriceCategories(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
      });
  }

  function loadPriceCategories() {
    setPriceCategoriesOptionsLoading(true);
    searchPriceCategoriesRequest(params)
      .then((res) => {
        setPriceCategoriesOptions(res.results);
        setPriceCategoriesOptionsLoading(false);
      })
      .catch((err) => {
        setPriceCategoriesOptions([]);
      });
  }

  useEffect(() => {
    if (init) listPriceCategories(params);
    else if (params.search && params.search.length > 0) {
      loadPriceCategories(params);
    }
  }, [params]);

  return {
    pageLoading,
    next,
    priceCategories,
    count,
    priceCategoriesOptionsLoading,
    listPriceCategories,
    setParams,
    priceCategoriesOptions
  };
}
