import { listRolesRequest } from '../../api';
import { useEffect, useState } from 'react';
import appNotify from '../appNotify';

export default function useRoles(init = false) {
  const [pageLoading, setPageLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  function listRoles() {
    setPageLoading(true);
    listRolesRequest()
      .then((res) => {
        console.log(res);
        setRoles(res);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to load roles');
      });
  }

  useEffect(() => {
    if (init) listRoles();
  }, []);

  return { pageLoading, roles, listRoles, setRoles };
}
