import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import { getGoodConsignmentsByID } from '../../../../utils/api';
import { useEffect, useState } from 'react';
import appNotify from '../../../../utils/libs/appNotify';
import ConsignedGoodCollapse from './ConsignedGoodCollapse';
import { TableListSkeleton } from '../../../../ui-component/Skeletons';

function ViewGood({ open, onClose, goodID, good }) {
  const { t } = useTranslation();
  const [pageLoading, setPageLoading] = useState(true);
  const [goodCons, setGoodCons] = useState([]);
  function getGoodConsignments() {
    getGoodConsignmentsByID(goodID)
      .then((res) => {
        console.log('Res: ', res);
        setGoodCons(res);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to load good consignments');
      });
  }

  useEffect(() => {
    getGoodConsignments();
  }, []);
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={() => onClose()}>
      <DialogTitle>
        <DialogTitle style={{ fontWeight: 'bold', fontSize: 20 }}>
          {good.supplier_receipt_no} - {good.name} - {t('goods.consigned_details')} {!pageLoading && `(${goodCons.length})`}
        </DialogTitle>
      </DialogTitle>
      <DialogContent>
        <TableListSkeleton isLoading={pageLoading}>{!pageLoading && <ConsignedGoodCollapse goodCons={goodCons} />}</TableListSkeleton>
      </DialogContent>
    </Dialog>
  );
}

ViewGood.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  goodID: PropTypes.any,
  good: PropTypes.any
};

export default ViewGood;
