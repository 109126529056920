import { listInvoicesRequest, searchGoodsRequest, searchInvoicesRequest } from '../../api';
import { useEffect, useState } from 'react';

export default function useInvoices(init = false, initialParams = {}) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [next, setNext] = useState(null);
  const [invoiceInputValue, setInvoiceInputValue] = useState('');
  const [invoiceOptions, setInvoiceOptions] = useState([]);
  const [invoiceOptionsLoading, setInvoiceOptionsLoading] = useState(false);
  const [params, setParams] = useState({ page: null, search: null, ...initialParams });
  function listInvoices() {
    setPageLoading(true);
    listInvoicesRequest(params)
      .then((res) => {
        console.log('Res: ', res);
        setInvoices(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
      });
  }

  // function loadInvoices() {
  //   setInvoiceOptionsLoading(true);
  //   searchInvoicesRequest(invoiceInputValue)
  //     .then((res) => {
  //       console.log('Res: ', res);
  //       setInvoiceOptions(res.results);
  //       setInvoiceOptionsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log('Err: ', err);
  //       setInvoiceOptionsLoading([]);
  //     });
  // }

  useEffect(() => {
    if (init) listInvoices();
  }, [params]);

  return { setParams, pageLoading, next, invoices, count, invoiceOptionsLoading, setInvoiceInputValue, invoiceOptions };
}
