import { listCustomersRequest, searchCustomersRequest } from '../../api';
import { useEffect, useState } from 'react';

export default function useCustomers(init = false, initialParams = {}) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [next, setNext] = useState(null);
  const [params, setParams] = useState({
    search: null,
    page: null,
    ...initialParams
  });
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerOptionsLoading, setCustomerOptionsLoading] = useState(false);
  function listCustomers() {
    setPageLoading(true);
    listCustomersRequest(params)
      .then((res) => {
        setCustomers(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
      });
  }

  function loadCustomers() {
    setCustomerOptionsLoading(true);
    searchCustomersRequest(params)
      .then((res) => {
        setCustomerOptions(res.results);
        setCustomerOptionsLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        setCustomerOptions([]);
      });
  }

  useEffect(() => {
    if (init) listCustomers();
    else if (params.search && params.search.length > 0) {
      loadCustomers();
    }
  }, [params]);

  return { pageLoading, next, customers, count, listCustomers, customerOptionsLoading, setParams, customerOptions };
}
