import * as Yup from 'yup';
import { Formik } from 'formik';
import { Select, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AnimateButton from '../../../ui-component/extended/AnimateButton';
import { UNITS } from '../../../utils/libs/constants';
import { useTranslation } from 'react-i18next';
import { AsyncSearchCustomersField, AsyncSearchSuppliersField, AsyncSearchWarehousesField } from '../../../ui-component/AsyncFields';
import PropTypes from 'prop-types';

function EditGoodForm({ loading, onSubmit, initialValues = null }) {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={
        initialValues || {
          name: null,
          pkgs: '',
          supplier: '',
          supplier_receipt_no: '',
          quantity: '',
          warehouse: null
        }
      }
      validationSchema={Yup.object().shape({
        warehouse: Yup.object().shape({
          value: Yup.string().max(255).required(t('common.errors.required.warehouse')),
          label: Yup.string().max(255).required(t('common.errors.required.warehouse'))
        }),
        supplier: Yup.object().shape({
          value: Yup.string().max(255).required(t('common.errors.suppliers.warehouse')),
          label: Yup.string().max(255).required(t('common.errors.required.warehouse'))
        }),
        pkgs: Yup.string().max(255).required(t('common.errors.required.pkgs')),
        quantity: Yup.string().max(255).required(t('common.errors.required.quantity')),
        name: Yup.string().max(255).required(t('common.errors.required.name')),
        supplier_receipt_no: Yup.string().max(255).required(t('common.errors.required.supplier_receipt_no'))
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        onSubmit({
          ...values,
          supplier: values.supplier.value,
          warehouse: values.warehouse.value,
          customer: values.customer.value
        });
      }}
    >
      {({ setFieldValue, errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('goods.item')}</InputLabel>
                <OutlinedInput
                  id="email-login"
                  type="text"
                  value={values.name}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={`${t('common.eg')}. Jeans`}
                  fullWidth
                  error={Boolean(touched.name && errors.name)}
                />
                {touched.name && errors.name && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.name}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('common.customer')}</InputLabel>
                <AsyncSearchCustomersField
                  value={values.customer ? values.customer : null}
                  onChange={(newValue) => {
                    if (newValue) {
                      setFieldValue('customer', newValue);
                      return;
                    }
                    setFieldValue('customer', null);
                  }}
                />
                {touched.customer && errors.customer && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.customer.label}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('common.supplier')}</InputLabel>
                <AsyncSearchSuppliersField
                  value={values.supplier ? values.supplier : null}
                  onChange={(newValue) => {
                    if (newValue) {
                      setFieldValue('supplier', newValue);
                      return;
                    }
                    setFieldValue('supplier', '');
                  }}
                />
                {touched.supplier && errors.supplier && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.supplier.label}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('goods.receipt_no')}</InputLabel>
                <OutlinedInput
                  id="email-login"
                  type="text"
                  value={values.supplier_receipt_no}
                  name="supplier_receipt_no"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={`${t('common.eg')}. 28108418`}
                  fullWidth
                  error={Boolean(touched.supplier_receipt_no && errors.supplier_receipt_no)}
                />
                {touched.supplier_receipt_no && errors.supplier_receipt_no && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.supplier_receipt_no}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('goods.unit')}</InputLabel>
                <Select
                  labelId="origin"
                  id="origin"
                  value={values.unit}
                  name="unit"
                  placeholder={t('goods.select_unit')}
                  onChange={handleChange}
                  style={{
                    height: 50,
                    borderRadius: 30,
                    zIndex: 100000000
                  }}
                  error={Boolean(touched.unit && errors.origin)}
                >
                  {Object.entries(UNITS).map(([key, value]) => (
                    <MenuItem style={{ zIndex: 100000000000000 }} key={key} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
                {touched.origin && errors.origin && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.origin}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('goods.qty')}</InputLabel>
                <OutlinedInput
                  id="email-login"
                  type="number"
                  value={values.quantity}
                  name="quantity"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={`${t('common.eg')}. 4`}
                  fullWidth
                  error={Boolean(touched.quantity && errors.quantity)}
                />
                {touched.quantity && errors.quantity && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.quantity}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('goods.pkgs')}</InputLabel>
                <OutlinedInput
                  id="email-login"
                  type="number"
                  value={values.pkgs}
                  name="pkgs"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="eg. 3"
                  fullWidth
                  error={Boolean(touched.pkgs && errors.pkgs)}
                />
                {touched.pkgs && errors.pkgs && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.pkgs}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('common.warehouse')}</InputLabel>
                <AsyncSearchWarehousesField
                  value={values.warehouse ? values.warehouse : null}
                  onChange={(newValue) => {
                    if (newValue) {
                      setFieldValue('warehouse', newValue);
                      return;
                    }
                    setFieldValue('warehouse', '');
                  }}
                />
                {touched.warehouse && errors.warehouse && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.warehouse.label}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center" mg={3}>
            <Grid item xs={6}>
              <AnimateButton>
                <LoadingButton
                  disableElevation
                  loading={loading}
                  disabled={loading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {t('common.submit')}
                </LoadingButton>
              </AnimateButton>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

EditGoodForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.any,
  initialValues: PropTypes.any
};

export default EditGoodForm;
