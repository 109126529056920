import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import { Transition } from '../../../../ui-component';
import { Button, DialogContent, DialogTitle, Grid } from '@mui/material';
import { IconChevronLeft, IconEye, IconFileInvoice, IconListCheck, IconPackage, IconUser } from '@tabler/icons-react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CustomerInvoices from '../../../customers/components/customer-details/CustomerInvoices';
import CustomerCargos from '../../../customers/components/customer-details/CustomerCargos';
import CustomerDetails from '../../../customers/components/customer-details/CustomerDetails';
import PropTypes from 'prop-types';
import UserDetails from './UserDetails';

function UserDetailsModal({ open, onClose, user = null }) {
  const [tab, setTab] = useState(0);
  const [reload, setReload] = useState(false);
  const { t } = useTranslation();
  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  function handleClose() {
    onClose(reload);
  }

  return (
    <Dialog TransitionComponent={Transition} fullScreen fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle style={{ fontWeight: 'bold', fontSize: 20 }}>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item>
            <Grid container>
              <Grid item>
                <Button
                  startIcon={<IconChevronLeft />}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {t('common.go_back')}
                </Button>
              </Grid>
              <Grid item>{user.full_name}</Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Tabs value={tab} onChange={handleChange} centered>
              <Tab value={0} icon={<IconUser />} iconPosition="start" label={t('users.profile')} />
            </Tabs>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center">
          <Grid item xs={11}>
            {/* tabs content here */}
            {/* {tab === 2 && <CustomerInvoices customerId={customer.id} />} */}
            {/* {tab === 1 && <CustomerCargos customerID={customer.id} />} */}
            {tab === 0 && <UserDetails user={user} onClose={() => onClose(true)} />}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

UserDetailsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.any
};
export default UserDetailsModal;
