// import { lazy } from 'react';
// import Loadable from '../ui-component/Loadable';
import MinimalLayout from '../layout/MinimalLayout';
import CustomerPackingList from '../pages/utilities/CustomerPackingList';
import Invoice from '../pages/invoices/components/Invoice';

// const CustomerPackingList = Loadable(lazy(() => import('../pages/utilities/CustomerPackingList')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const Utils = {
  path: '/u',
  // element: <MinimalLayout />,
  children: [
    {
      path: 'cpl',
      element: <CustomerPackingList />
    },
    {
      path: 'inv/:invoice_id',
      element: <Invoice />
    }
    // {
    //     path: '/register',
    //     element: <AuthRegister3 />
    // },
    // {
    //     path: '/stepers',
    //     element: <Stepers />
    // }
  ]
};

export default Utils;
