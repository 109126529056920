import { DialogContent, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import EditGoodForm from './components/EditGoodForm';
import { editGoodRequest, getGoodByIDRequest } from '../../utils/api';
import appNotify from '../../utils/libs/appNotify';
import { useTranslation } from 'react-i18next';

function EditGoodDialog({ open, onClose, onEditedGood, goodID = null }) {
  const [loading, setLoading] = useState(false);
  const [good, setGood] = useState(null);
  const { t } = useTranslation();
  function editGood(values) {
    setLoading(true);
    editGoodRequest(goodID, values)
      .then((res) => {
        console.log('Res: ', res);
        appNotify.success(t('goods.success.edited'));
        setLoading(false);
        onClose(res.good);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error(t('common.tryAgain'));
        setLoading(false);
      });
  }

  function getGood() {
    setLoading(true);
    getGoodByIDRequest(goodID)
      .then((res) => {
        setGood(res.good);
        setLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Error occurred in fetching good details');
        onClose();
        setLoading(false);
      });
  }

  useEffect(() => {
    if (goodID !== null) {
      getGood();
    }
  }, [goodID]);

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={() => onClose()}>
      <DialogTitle style={{ fontWeight: 'bold', fontSize: 20 }}>{t('goods.edit')}</DialogTitle>
      <DialogContent>
        {good !== null && <EditGoodForm loading={loading} initialValues={good} onSubmit={(values) => editGood(values)} />}
      </DialogContent>
    </Dialog>
  );
}

export default EditGoodDialog;
