import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AnimateButton from '../../../ui-component/extended/AnimateButton';
import AmountView, { CurrencyRate, NumberFormatter } from '../../../ui-component';
import { forwardRef, useState } from 'react';
import MainCard from '../../../ui-component/cards/MainCard';
import { PAYMENT_TYPES, SERVICE_PROVIDERS } from '../../../utils/libs/constants';

const yupValues = {
  auto: {
    payment_reference: Yup.string().max(255).nullable()
  },
  required: {
    payment_reference: Yup.string().max(255).required('Payment reference is required').nullable()
  },
  mobile_payment: {
    payment_reference: Yup.string().max(255).required('Payment reference is required').nullable(),
    payer_mobile: Yup.string().max(255).required('Payer mobile is required').nullable(),
    payment_service_provider: Yup.string().max(255).required('Payment service provider is required').nullable(),
    payer_name: Yup.string().max(255).required('Payer name is required').nullable()
  }
};
function AddPaymentForm({ loading, onSubmit, invoice }) {
  const [currency, setCurrency] = useState('USD');
  const [amount, setAmount] = useState(invoice.balance);
  const [autoGenerate, setAutoGenerate] = useState(true);
  const [yupValue, setYupValue] = useState(yupValues.auto);
  const [countryCode, setCountryConde] = useState('255');

  // eslint-disable-next-line react/no-unstable-nested-components
  const NumericCustom = forwardRef((props, ref) => (
    <NumberFormatter {...props} maxFloatValue={parseFloat(amount)} prefix={`${currency} `} />
  ));

  return (
    <MainCard
      title={
        <Grid container spacing={3} alignItems="center">
          <Grid item>Add Payment</Grid>
          <Grid item>
            <CurrencyRate changeable={false} />
          </Grid>
        </Grid>
      }
    >
      <Stack spacing={2}>
        <Formik
          initialValues={{
            payment_reference: null,
            amount: '',
            payment_type: PAYMENT_TYPES.CASH_PAYMENT,
            payment_service_provider: null,
            payer_mobile: null,
            payer_name: null
          }}
          validationSchema={Yup.object().shape({
            ...yupValue,
            amount: Yup.string().max(255).required('Amount is required'),
            payment_type: Yup.string().max(255).required('Payment type is required')
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            const payload = {
              ...values,
              bill: invoice.id,
              payer_mobile: values.payer_mobile !== null ? `255${values.payer_mobile}` : null,
              currency
            };

            console.log(payload);
            onSubmit(payload);
          }}
        >
          {({ resetForm, setFieldValue, errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3} mb={3}>
                <Grid item xs={12}>
                  <AmountView
                    onChange={(_currency, newAmount) => {
                      setCurrency(_currency);
                      setAmount(newAmount);
                      setFieldValue('amount', '');
                    }}
                    title="BALANCE AMOUNT"
                    amount={invoice.balance}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} mb={3}>
                <Grid item xs={6} md={3} lg={3}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="bootstrap">Payment Type</InputLabel>
                    <Select
                      labelId="payment-type"
                      id="payment-type"
                      value={values.payment_type}
                      name="payment_type"
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (newValue === PAYMENT_TYPES.MOBILE_PAYMENT) {
                          setYupValue(yupValues.mobile_payment);
                          setAutoGenerate(false);
                        } else {
                          setYupValue(yupValues.auto);
                          setAutoGenerate(true);
                        }
                        resetForm(); // resets form to have a clear start
                        setFieldValue('payment_type', newValue);
                      }}
                      error={Boolean(touched.payment_type && errors.payment_type)}
                    >
                      <MenuItem value={PAYMENT_TYPES.CASH_PAYMENT}>CASH</MenuItem>
                      <MenuItem value={PAYMENT_TYPES.MOBILE_PAYMENT}>MOBILE PAYMENT</MenuItem>
                    </Select>
                    {touched.payment_type && errors.payment_type && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {errors.payment_type}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={3} mb={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="bootstrap">Reference No</InputLabel>
                    <OutlinedInput
                      id="email-login"
                      type="text"
                      value={values.payment_reference}
                      name="payment_reference"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="eg RST424D29U0"
                      fullWidth
                      disabled={autoGenerate}
                      error={Boolean(touched.payment_reference && errors.payment_reference)}
                    />
                    {touched.payment_reference && errors.payment_reference && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {errors.payment_reference}
                      </FormHelperText>
                    )}
                    {/* <FormGroup> */}
                    {/*   <FormControlLabel */}
                    {/*     onChange={(e, c) => { */}
                    {/*       setAutoGenerate(c); */}
                    {/*       if (c) { */}
                    {/*         setRefNoYup(yupPayRefNo.auto); */}
                    {/*       } else { */}
                    {/*         setRefNoYup(yupPayRefNo.required); */}
                    {/*       } */}
                    {/*     }} */}
                    {/*     checked={autoGenerate} */}
                    {/*     control={<Checkbox />} */}
                    {/*     label="Auto generate payment reference" */}
                    {/*   /> */}
                    {/* </FormGroup> */}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="bootstrap">Amount</InputLabel>
                    <OutlinedInput
                      id="email-login"
                      type="text"
                      value={values.amount}
                      name="amount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={currency === 'USD' ? 'eg. USD 4,050' : 'eg. TZS 3,005,000'}
                      inputComponent={NumericCustom}
                      fullWidth
                      maxValue={200}
                      style={{ padding: 0 }}
                      error={Boolean(touched.amount && errors.amount)}
                    />
                    {touched.amount && errors.amount && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {errors.amount}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                {values.payment_type === PAYMENT_TYPES.MOBILE_PAYMENT && (
                  <>
                    <Grid item xs={12} md={6} lg={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="bootstrap">Payer Mobile</InputLabel>
                        <OutlinedInput
                          id="payer-mobile"
                          type="number"
                          value={values.payer_mobile}
                          name="payer_mobile"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.value.toString().length < 10) {
                              handleChange(e);
                            }
                          }}
                          placeholder="eg. 7111..."
                          fullWidth
                          style={{ padding: 0 }}
                          startAdornment={
                            <InputAdornment style={{ padding: 0 }} position="start">
                              {' '}
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={countryCode}
                                onChange={handleChange}
                              >
                                <MenuItem value="255">+255</MenuItem>
                              </Select>
                            </InputAdornment>
                          }
                          error={Boolean(touched.payer_mobile && errors.payer_mobile)}
                        />
                        {touched.payer_mobile && errors.payer_mobile && (
                          <FormHelperText error id="standard-weight-helper-text-email-login">
                            {errors.payer_mobile}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="bootstrap">Payer Name</InputLabel>
                        <OutlinedInput
                          id="email-login"
                          type="text"
                          value={values.payer_name}
                          name="payer_name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="eg. John Doe"
                          fullWidth
                          maxValue={200}
                          style={{ padding: 0 }}
                          error={Boolean(touched.payer_name && errors.payer_name)}
                        />
                        {touched.payer_name && errors.payer_name && (
                          <FormHelperText error id="standard-weight-helper-text-email-login">
                            {errors.payer_name}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="bootstrap">Service Provider</InputLabel>
                        <Select
                          labelId="demo-simple-select-disabled-label"
                          id="demo-simple-select-disabled"
                          value={values.payment_service_provider}
                          onChange={handleChange}
                          name="payment_service_provider"
                          error={Boolean(touched.payment_service_provider && errors.payment_service_provider)}
                        >
                          <MenuItem value={SERVICE_PROVIDERS.TIGO}>TIGO</MenuItem>
                          <MenuItem value={SERVICE_PROVIDERS.VODACOM}>VODACOM</MenuItem>
                          <MenuItem value={SERVICE_PROVIDERS.AIRTEL}>AIRTEL</MenuItem>
                        </Select>
                        {touched.payment_service_provider && errors.payment_service_provider && (
                          <FormHelperText error id="standard-weight-helper-text-email-login">
                            {errors.payment_service_provider}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container direction="row" justifyContent="center" alignItems="center" mg={3}>
                <Grid item xs={6}>
                  <AnimateButton>
                    <LoadingButton
                      disableElevation
                      loading={loading}
                      disabled={loading}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </LoadingButton>
                  </AnimateButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Stack>
    </MainCard>
  );
}

export default AddPaymentForm;
