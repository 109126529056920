import { listUsersRequest, searchUsersRequest } from '../../api';
import { useEffect, useState } from 'react';
import appNotify from '../appNotify';

export default function useUsers(init = false, initialsParams = {}) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [next, setNext] = useState(null);
  const [params, setParams] = useState({
    page: null,
    search: null,
    ...initialsParams
  });
  const [usersOptions, setUsersOptions] = useState([]);
  const [usersOptionsLoading, setUsersOptionsLoading] = useState(false);
  function listPayments() {
    setPageLoading(true);
    listUsersRequest(params)
      .then((res) => {
        console.log(res.results);
        setUsers(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to load payments');
      });
  }

  function loadPayments() {
    setUsersOptionsLoading(true);
    searchUsersRequest(params)
      .then((res) => {
        setUsersOptions(res.results);
        setUsersOptionsLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Error in loading invoice payments');
        setUsersOptions([]);
      });
  }

  useEffect(() => {
    if (init) listPayments();
    else if (params.search && params.search.length > 0) {
      loadPayments();
    }
  }, [params]);

  return { setUsers, pageLoading, next, users, count, usersOptions, setParams, usersOptionsLoading };
}
