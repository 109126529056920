import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Box, Collapse, IconButton } from '@mui/material';
import { useState } from 'react';
import { IconChevronDown, IconChevronUp, IconEdit, IconTrash } from '@tabler/icons-react';
import { TableListSkeleton } from '../../../ui-component/Skeletons';
import PropTypes from 'prop-types';
import { Descriptions } from 'antd';
import { PAYMENT_TYPES } from '../../../utils/libs/constants';

function Row({ row, columns }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
        {columns.map((column) => {
          const value = row[column.id];
          if (column.id === 'actions') {
            return (
              <TableCell align="left">
                {row.payment_type === PAYMENT_TYPES.MOBILE_PAYMENT && (
                  <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <IconChevronUp /> : <IconChevronDown />}
                  </IconButton>
                )}
                <IconButton aria-label="expand row" size="small" onClick={() => {}}>
                  <IconEdit />
                </IconButton>
                <IconButton aria-label="expand row" size="small" onClick={() => {}}>
                  <IconTrash />
                </IconButton>
              </TableCell>
            );
          }
          return (
            <TableCell key={column.id} align={column.align}>
              {column.format && typeof value === 'number' ? column.format(value) : value}
            </TableCell>
          );
        })}
      </TableRow>
      {row.payment_type === PAYMENT_TYPES.MOBILE_PAYMENT && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Descriptions
                  title="Payment details"
                  layout="vertical"
                  bordered
                  items={[
                    {
                      key: '1',
                      label: 'Payer Name',
                      children: row.payer_name
                    },
                    {
                      key: '2',
                      label: 'Payer Mobile',
                      children: row.payer_mobile
                    },
                    {
                      key: '3',
                      label: 'Service Provider',
                      children: row.payment_service_provider
                    }
                  ]}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

Row.propTypes = {
  columns: PropTypes.array,
  row: PropTypes.any
};

function InvoicePaymentsTable({
  showPagination = true,
  pageLoading = true,
  next = null,
  onNextPage,
  columns = [],
  payments = [],
  count = 0
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowPayment, setRowPayment] = useState(null);

  const open = Boolean(anchorEl);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onNextPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (event, payment) => {
    setRowPayment(payment);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableListSkeleton isLoading={pageLoading}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.map((row) => (
                <Row openMenu={open} row={row} columns={columns} handleClose={handleClose} handleClick={handleClick} anchorEl={anchorEl} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableListSkeleton>
      {showPagination && (
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextButton={{
            disabled: pageLoading || !next
          }}
          previousButton={{
            disabled: pageLoading || page === 0
          }}
        />
      )}
    </Paper>
  );
}

InvoicePaymentsTable.propTypes = {
  pageLoading: PropTypes.bool,
  showPagination: PropTypes.bool,
  next: PropTypes.any,
  columns: PropTypes.array,
  payments: PropTypes.array,
  count: PropTypes.number,
  onNextPage: PropTypes.func
};

export default InvoicePaymentsTable;
