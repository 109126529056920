import Button from '@mui/material/Button';
import { Image, Result } from 'antd';
import pageUnderConstruction from '../assets/images/page_under_construction.gif';
import { useNavigate } from 'react-router';

function PageUnderConstruction() {
  const navigate = useNavigate();
  const navigateHome = () => {
    navigate('/', { replace: true });
  };
  return (
    <Result
      title="Page under construction!"
      icon={<Image height="50vh" src={pageUnderConstruction} preview={false} />}
      subTitle="Sorry, this page is being constructed and refined, it will be available very soon."
      extra={
        <Button onClick={navigateHome} type="primary">
          Back Home
        </Button>
      }
    />
  );
}

export default PageUnderConstruction;
