import { listCustomerCargosRequest } from '../../api';
import { useEffect, useState } from 'react';
import appNotify from '../appNotify';
import { useTranslation } from 'react-i18next';
import { debuggerConsole } from '../index';

export default function useCargos(initialParams = { page: null, customer: null }) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [cargos, setCargos] = useState([]);
  const [next, setNext] = useState(null);
  const { t } = useTranslation();
  const [params, setParams] = useState({
    ...initialParams
  });
  function listCargos() {
    setPageLoading(true);
    listCustomerCargosRequest(params)
      .then((res) => {
        console.log(res);
        setCargos(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch((err) => {
        debuggerConsole(err);
        appNotify.error(t('cargos.failed_to_load'));
      });
  }

  useEffect(() => {
    listCargos();
  }, [params]);

  return { setCargos, setParams, pageLoading, next, cargos, count };
}
