import * as Yup from 'yup';
import { Formik } from 'formik';
import { Divider, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Button } from 'antd';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import { useState } from 'react';
import { ID_TYPES } from '../../../utils/libs/constants';
import { DeleteOutline } from '@mui/icons-material';
import AnimateButton from '../../../ui-component/extended/AnimateButton';
import { IconMinus } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import appNotify from '../../../utils/libs/appNotify';
import { deleteCustomerCellphoneRequest } from '../../../utils/api';
import ConfirmationDialog from '../../../ui-component/ConfirmationDialog';

const defaultCellphoneField = {
  id: null,
  cellphone: '',
  is_main: false,
  error: false
};

function CustomerForm({ loading, onSubmit, initialValues = { customer: null, cellphones: null } }) {
  const isEdit = initialValues.customer !== null;
  const { t } = useTranslation();
  const [cellphones, setCellphones] = useState(
    initialValues.cellphones !== null ? initialValues.cellphones : [{ ...defaultCellphoneField, is_main: true }]
  );
  const [countryCode, setCountryConde] = useState('255');
  const [openConfirmationDialog, setConfirmationDialog] = useState(false);
  const [rowIdx, setRowIdx] = useState(null);
  const [rowCellphone, setRowCellphone] = useState(null);
  const [idType, setIDType] = useState(ID_TYPES.NIDA);

  function addCellphoneField() {
    setCellphones((prevState) => [...prevState, defaultCellphoneField]);
  }

  function removeCellphoneField(idx) {
    setCellphones((prevState) => {
      const newList = [].concat(prevState);
      const cellphone = newList.splice(idx, 1)[0];
      return newList;
    });
  }

  function deleteCellphone() {
    return deleteCustomerCellphoneRequest(rowCellphone.id)
      .then(() => {
        removeCellphoneField(rowCellphone);
        appNotify.success('Cellphone deleted successfully');
        return true;
      })
      .catch(() => {
        appNotify.error('Failed to delete customer cellphone');
        return false;
      });
  }

  function showMinus(cellphone, idx) {
    return !cellphone.is_main && idx !== 0;
  }

  function showPlus(idx) {
    return idx === cellphones.length - 1;
  }

  return (
    <>
      <Formik
        initialValues={
          initialValues.customer !== null
            ? initialValues.customer
            : {
                full_name: '',
                email: '',
                address: '',
                id_number: '',
                other_names: '',
                company_name: ''
              }
        }
        validationSchema={Yup.object().shape({
          full_name: Yup.string().max(255).required(t('customers.errors.required.full_name')),
          email: Yup.string().email('Must be a valid email').max(255),
          address: Yup.string().max(255).required(t('customers.errors.required.address')),
          other_names: Yup.string().max(255),
          id_number: Yup.string().max(255).nullable(),
          company: Yup.string().max(255).nullable()
        })}
        onSubmit={async (values) => {
          let cont = true;
          cellphones.forEach((cell, idx) => {
            if (cell.cellphone.toString().length < 9) {
              cont = false;
              setCellphones((prevState) =>
                prevState.map((c, i) => {
                  if (i === idx) {
                    return { ...c, error: true };
                  }
                  return c;
                })
              );
            }
          });
          if (!cont) return;
          onSubmit({
            other_names: values.other_names,
            cellphones: cellphones.map((i, x) => ({ ...i, cellphone: `255${i.cellphone}` })),
            address: values.address,
            id_type: values.id_type,
            id_number: values.id_number,
            company_name: values.company_name,
            user: { email: values.email || null, full_name: values.full_name }
          });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} md={3} lg={3}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="bootstrap">{t('customers.full')}</InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="text"
                    value={values.full_name}
                    name="full_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="eg. John Doe Philips"
                    fullWidth
                    error={Boolean(touched.full_name && errors.full_name)}
                  />
                  {touched.full_name && errors.full_name && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.full_name}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="bootstrap">{t('customers.other_names')}</InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="text"
                    value={values.other_names}
                    name="other_names"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="eg. ..."
                    fullWidth
                    error={Boolean(touched.other_names && errors.other_names)}
                  />
                  {touched.other_names && errors.other_names && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.other_names}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="bootstrap">{t('customers.email')}</InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="text"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={`${t('common.eg')} example@example.com`}
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.email}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="bootstrap">Physical Address</InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="text"
                    value={values.address}
                    name="address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="eg. Msimbazi, Daresalaam"
                    fullWidth
                    error={Boolean(touched.address && errors.address)}
                  />
                  {touched.address && errors.address && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.address}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="bootstrap">ID Number</InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="text"
                    value={values.id_number}
                    name="id_number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="eg. 20023029492012293"
                    fullWidth
                    error={Boolean(touched.id_number && errors.id_number)}
                  />
                  {touched.id_number && errors.id_number && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.id_number}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="bootstrap">ID Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-disabled-label"
                    id="demo-simple-select-disabled"
                    value={idType}
                    onChange={(e) => {
                      // setRoleError(false);
                      // setRole(e.target.value);
                    }}
                    // error={Boolean(role_error)}
                  >
                    <MenuItem value={ID_TYPES.NIDA}>NIDA</MenuItem>
                    <MenuItem value={ID_TYPES.VOTE}>Vote ID</MenuItem>
                  </Select>
                  {touched.amount && errors.amount && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.amount}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="bootstrap">Company Name</InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="text"
                    value={values.company_name}
                    name="company_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="eg. Vunja Bei"
                    fullWidth
                    error={Boolean(touched.company_name && errors.company_name)}
                  />
                  {touched.company_name && errors.company_name && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.company_name}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Divider>Customer Phone Numbers</Divider>
              </Grid>
              {cellphones.map((cellphone, idx) => (
                <Grid item xs={12} md={6} lg={6} key={idx}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="bootstrap">
                      Phone Number{' '}
                      {cellphone.is_main ? (
                        <WorkspacePremiumIcon color="warning" />
                      ) : (
                        <WorkspacePremiumOutlinedIcon style={{ color: 'rgba(210, 209, 209, 0.25)' }} />
                      )}
                    </InputLabel>
                    <Grid spacing={1} container justifyContent="flex-start" alignItems="center">
                      <Grid item xs={10}>
                        <OutlinedInput
                          id="email-login"
                          type="number"
                          value={cellphone.cellphone}
                          name="cellphone"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.value.toString().length < 10) {
                              setCellphones((prevState) =>
                                prevState.map((c, i) => {
                                  if (i === idx) {
                                    return { ...c, cellphone: e.target.value, error: false };
                                  }
                                  return c;
                                })
                              );
                            }
                          }}
                          placeholder="eg. 7111..."
                          fullWidth
                          style={{ padding: 0 }}
                          startAdornment={
                            <InputAdornment style={{ padding: 0 }} position="start">
                              {' '}
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={countryCode}
                                onChange={handleChange}
                              >
                                <MenuItem value="255">+255</MenuItem>
                              </Select>
                            </InputAdornment>
                          }
                          error={Boolean(cellphone.error)}
                        />
                        {cellphone.error && (
                          <FormHelperText error id="standard-weight-helper-text-email-login">
                            Input a valid cellphone
                          </FormHelperText>
                        )}
                      </Grid>
                      {showMinus(cellphone, idx) && (
                        <Grid item xs={1}>
                          <Button
                            onClick={() => {
                              if (cellphone.id === null) {
                                removeCellphoneField(idx);
                              } else {
                                setRowCellphone(cellphone);
                                setRowIdx(idx);
                                setConfirmationDialog(true);
                              }
                            }}
                            shape="circle"
                            danger
                            icon={cellphone.id === null ? <IconMinus /> : <DeleteOutline />}
                          />
                        </Grid>
                      )}
                      {showPlus(idx) && (
                        <Grid item xs={1}>
                          <Button onClick={addCellphoneField} shape="circle" icon={<AddOutlinedIcon />} />
                        </Grid>
                      )}
                    </Grid>
                    {cellphone.error && errors.error && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {errors.error}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              ))}
            </Grid>

            <Grid container direction="row" justifyContent="center" alignItems="center" mg={3}>
              <Grid item xs={6}>
                <AnimateButton>
                  <LoadingButton
                    disableElevation
                    loading={loading}
                    disabled={loading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </LoadingButton>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <ConfirmationDialog
        description="You are about delete this customer cellphone"
        open={openConfirmationDialog}
        setOpen={setConfirmationDialog}
        onConfirm={deleteCellphone}
        onClose={() => {
          setRowIdx(null);
          setRowCellphone(null);
          setConfirmationDialog(false);
        }}
      />
    </>
  );
}

CustomerForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.any
};

export default CustomerForm;
