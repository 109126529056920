import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { IconEdit, IconEye, IconReceipt, IconTrash } from '@tabler/icons-react';
import { TableListSkeleton } from '../../../ui-component/Skeletons';
import EditGoodDialog from '../EditGoodDialog';
import { GOODS_STATUS } from '../../../utils/libs/constants';
import PropTypes from 'prop-types';
import ConfirmDeleteGoodModal from './ConfirmDeleteModal';
import ViewGood from './view';
import PermissionProvider from '../../users-roles-manager/components/PermissionHandler';
import { PERMISSIONS } from '../../../utils/libs/permissions';
import { useTranslation } from 'react-i18next';
import ReceiptViewModal from './view/ReceiptViewModal';

function Row({ row, columns, setGoods, onDelete }) {
  const [open, setOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [openReceiptViewModal, setOpenReceiptViewModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
        {columns.map((column, idx) => {
          const value = row[column.id];
          if (column.id === 'actions') {
            return (
              <TableCell key={idx} align="left">
                <Tooltip title={t('goods.receipt')}>
                  <IconButton aria-label="expand row" size="small" onClick={() => setOpenReceiptViewModal(true)}>
                    <IconReceipt />
                  </IconButton>
                </Tooltip>
                {row.status === GOODS_STATUS.CONSIGNED && (
                  <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    <IconEye />
                  </IconButton>
                )}
                {row.status === GOODS_STATUS.PENDING && (
                  <>
                    <PermissionProvider permission={PERMISSIONS.GOODS_UPDATE}>
                      <IconButton aria-label="expand row" size="small" onClick={() => setOpenEditDialog(true)}>
                        <IconEdit />
                      </IconButton>
                    </PermissionProvider>
                    <PermissionProvider permission={PERMISSIONS.GOODS_DELETE}>
                      <IconButton aria-label="expand row" size="small" onClick={() => setOpenConfirmDeleteModal(true)}>
                        <IconTrash />
                      </IconButton>
                    </PermissionProvider>
                  </>
                )}
              </TableCell>
            );
          }
          return (
            <TableCell key={column.id} align={column.align}>
              {column.format && typeof value === 'number' ? column.format(value) : value}
            </TableCell>
          );
        })}
      </TableRow>
      {open && <ViewGood good={row} open={open} onClose={() => setOpen(false)} goodID={row.id} />}
      {openEditDialog && (
        <EditGoodDialog
          open={openEditDialog}
          onClose={(good = null) => {
            setOpenEditDialog(false);
            if (good !== null) {
              setGoods((prevState) => prevState.map((g) => (g.id === row.id ? good : g)));
            }
          }}
          goodID={row.id}
        />
      )}
      {openConfirmDeleteModal && (
        <ConfirmDeleteGoodModal
          good={row}
          open={openConfirmDeleteModal}
          onClose={() => {
            setOpenConfirmDeleteModal(false);
          }}
          onDelete={() => {
            onDelete();
            setOpenConfirmDeleteModal(false);
          }}
        />
      )}
      {openReceiptViewModal && (
        <ReceiptViewModal
          receipt={row}
          open={openReceiptViewModal}
          onClose={() => {
            setOpenReceiptViewModal(false);
          }}
        />
      )}
    </>
  );
}

Row.propTypes = {
  row: PropTypes.any,
  columns: PropTypes.array,
  setGoods: PropTypes.func,
  onDelete: PropTypes.func
};

function GoodsTable({
  showPagination = true,
  onNextPage,
  pageLoading = true,
  next = null,
  columns = [],
  goods = [],
  count = 0,
  setGoods,
  onDelete
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    onNextPage(newPage + 1);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableListSkeleton isLoading={pageLoading}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {goods.map((row, idx) => (
                <Row key={idx} columns={columns} row={row} setGoods={setGoods} onDelete={onDelete} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableListSkeleton>
      {showPagination && (
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextButton={{
            disabled: pageLoading || !next
          }}
          previousButton={{
            disabled: pageLoading || page === 0
          }}
        />
      )}
    </Paper>
  );
}

GoodsTable.propTypes = {
  pageLoading: PropTypes.bool,
  next: PropTypes.any,
  columns: PropTypes.array,
  goods: PropTypes.array,
  onNextPage: PropTypes.func,
  setGoods: PropTypes.func,
  onDelete: PropTypes.func,
  showPagination: PropTypes.bool,
  count: PropTypes.number
};

export default GoodsTable;
