import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Routes from './routes';
import themes from './themes';
import NavigationScroll from './layout/NavigationScroll';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Locales from './utils/locales';

// ==============================|| APP ||============================== //
// eslint-disable-next-line import/no-mutable-exports
export let globalNavigator = null;
// eslint-disable-next-line import/no-mutable-exports
export let snackbarRef = null;
function SnackbarReferenceProvider() {
  snackbarRef = useSnackbar();
  globalNavigator = useNavigate();
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

function App() {
  const customization = useSelector((state) => state.customization);
  return (
    <Locales>
      <StyledEngineProvider injectFirst>
        <SnackbarProvider maxSnack={3} autoHideDuration={5000} preventDuplicate>
          <SnackbarReferenceProvider />
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Routes />
              </LocalizationProvider>
            </NavigationScroll>
          </ThemeProvider>
        </SnackbarProvider>
      </StyledEngineProvider>
    </Locales>
  );
}

export default App;
