import { createSlice } from '@reduxjs/toolkit';
import { getToken } from '../../utils/api/handlers';
import { roleManager } from '../../utils/libs/permissions/manager';

// initial state
const initialState = {
  user: {},
  token: getToken()
};

// ==============================|| SLICE - MENU ||============================== //

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action) {
      localStorage.setItem('token', JSON.stringify(action.payload));
      state.token = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
      console.log('User to be put: ', action.payload);
      roleManager.setUser(action.payload);
    }
  }
});

export default auth.reducer;

export const { setToken, setUser } = auth.actions;
