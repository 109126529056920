import { listDiscountsRequest } from '../../api';
import { useEffect, useState } from 'react';
import appNotify from '../appNotify';

export default function useDiscounts(init = false, params = {}) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [discounts, setDiscounts] = useState([]);
  const [next, setNext] = useState(null);
  // const [goodInputValue, setGoodInputValue] = useState('');
  // const [goodOptions, setGoodOptions] = useState([]);
  // const [goodOptionsLoading, setGoodOptionsLoading] = useState(false);
  function listDiscounts(page = null, searchValue = null) {
    setPageLoading(true);
    listDiscountsRequest(page, searchValue, params)
      .then((res) => {
        console.log('Res: ', res);
        setDiscounts(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to load discounts');
      });
  }

  // function loadGoods() {
  //   setGoodOptionsLoading(true);
  //   searchGoodsRequest(goodInputValue)
  //     .then((res) => {
  //       console.log('Res: ', res);
  //       setGoodOptions(res.results);
  //       setGoodOptionsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log('Err: ', err);
  //       setGoodOptions([]);
  //     });
  // }

  useEffect(() => {
    if (init) listDiscounts();
    // loadGoods();
  }, []);

  return { pageLoading, next, discounts, count };
}
