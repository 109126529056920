import { useState } from 'react';
import { addPaymentRequest } from '../../utils/api';
import appNotify from '../../utils/libs/appNotify';
import { useTranslation } from 'react-i18next';
import AddPaymentForm from './components/AddPaymentForm';
import PropTypes from 'prop-types';

function AddPayment({ invoice, setTab }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  function addPayment(values) {
    setLoading(true);
    addPaymentRequest(values)
      .then((res) => {
        appNotify.success('Payment recorded successfully');
        setTab(1);
        setLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to record payments');
        setLoading(false);
      });
  }
  return <AddPaymentForm invoice={invoice} loading={loading} onSubmit={(values) => addPayment(values)} />;
}

AddPayment.propTypes = {
  invoice: PropTypes.any,
  setTab: PropTypes.func
};

export default AddPayment;
