import { useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { currencyFormatter } from '../../utils/libs';
import TableBody from '@mui/material/TableBody';
import MainCard from '../../ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';

const res = {
  status: true,
  data: {
    pod: 'DSM',
    loading_date: '2023-12-28',
    arrival_date: '2024-01-31',
    name: 'Kelvin Remmy',
    tel: '255715357375',
    total_pkgs: 2,
    invoice_number: '#2212235',
    cbm: 2.0,
    total_amount: 6000.0,
    consignments: [
      {
        pkgs: 1,
        bn: 'NON',
        goods: [
          {
            qty: 1,
            unit: 'PIECES',
            description: 'Sweaters',
            receipt: '32324231'
          },
          {
            qty: 1,
            unit: 'PIECES',
            description: 'Sweaters',
            receipt: '32324231'
          }
        ],
        cbm: 1.0,
        amount: 3000.0
      },
      {
        pkgs: 1,
        bn: '2100391',
        goods: [
          {
            qty: 2,
            unit: 'PIECES',
            description: 'Jeans',
            receipt: '3991849'
          }
        ],
        cbm: 1.0,
        amount: 3000.0
      }
    ]
  }
};

const columns = [
  { id: 'pkgs', label: 'PKGS' },
  { id: 'bn', label: 'BN' },
  { id: 'qty', label: 'QTY' },
  { id: 'unit', label: 'UNIT' },
  { id: 'description', label: 'DESCRIPTION' },
  { id: 'receipt', label: 'RECEIPT' },
  { id: 'CBM', label: 'CBM' },
  { id: 'amount', label: 'AMOUNT' }
];

// main component to render the table
function CustomerPackingList({ width = '100wh' }) {
  const theme = useTheme();
  const leftBordered = { borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0', padding: 5 };
  const topBordered = { borderTop: '1px solid #f0f0f0' };
  const bolderText = { fontWeight: 'bolder', color: 'black' };
  const data = res.data;
  const rows = [];
  const [title, setTitle] = useState('Customer Packing List');
  res.data.consignments.forEach((i) => {
    i.goods.forEach((x, idx) => {
      let y = {
        qty: 2,
        unit: 'PIECES',
        description: 'Jeans',
        receipt: '3991849'
      };
      if (idx === 0) {
        const span = i.goods.length;
        y = {
          ...y,
          amount: { value: i.amount, span },
          bn: { value: i.bn, span },
          pkgs: { value: i.pkgs, span },
          cbm: { value: i.cbm, span }
        };
      }
      rows.push(y);
    });
    setTimeout(() => {
      setTitle('packing_list_PCIU9554493_TRUST.FM TRUST.FM 255757555534');
    }, 2000);
  });
  const styles = {
    paper: {
      padding: theme.spacing(1),
      paddingTop: 0,
      margin: 0
    },
    title: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1)
    },
    table: {
      width: '100%'
    },
    button: {
      margin: theme.spacing(1)
    },
    card: {
      backgroundColor: '#e3f2fd',
      border: '1px solid rgb(238, 238, 238)',
      padding: theme.spacing(2)
    }
  };
  return (
    <MainCard
      title={
        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item>
            <img src="http://cmts.hardtechinternational.com/u/image/gnm.png" alt="GNMCARGO" width="50" height={50} />
          </Grid>
          <Grid item>
            <Typography variant="h3" align="right">
              Customer Packing List{' '}
            </Typography>
          </Grid>
        </Grid>
      }
      className="divToPrint"
      style={{
        // maxWidth: 842,
        // minWidth: 842
        minHeight: '100vh',
        width
      }}
    >
      <Paper style={styles.paper}>
        <Grid container justifyContent="center" spacing={3} mb={3}>
          <Grid item xs={12}>
            <Table aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" colSpan={4} style={{ ...leftBordered, ...topBordered, fontSize: 16 }}>
                    PORT OF DISCHARGE
                  </TableCell>
                  <TableCell align="left" colSpan={4} style={{ ...leftBordered, ...bolderText, ...topBordered, fontSize: 16 }}>
                    {data.pod}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={4} style={{ ...leftBordered, fontSize: 16 }}>
                    LOADING DATE
                  </TableCell>
                  <TableCell align="left" colSpan={4} style={{ ...leftBordered, ...bolderText, fontSize: 16 }}>
                    {data.loading_date}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={4} style={{ ...leftBordered, fontSize: 16 }}>
                    ARRIVAL DATE
                  </TableCell>
                  <TableCell align="left" colSpan={4} style={{ ...leftBordered, ...bolderText, fontSize: 16 }}>
                    {data.arrival_date}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={4} style={{ ...leftBordered, fontSize: 16, maxWidth: 5 }}>
                    NAME: <span style={bolderText}>{data.name.toUpperCase()}</span>
                  </TableCell>
                  <TableCell align="left" colSpan={4} style={{ ...leftBordered, fontSize: 16 }}>
                    TOTAL PKGS: <span style={bolderText}>{data.total_pkgs} PKGS</span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={4} style={{ ...leftBordered, fontSize: 16 }}>
                    TEL: <span style={bolderText}>{data.tel}</span>
                  </TableCell>
                  <TableCell align="left" colSpan={4} style={{ ...leftBordered, fontSize: 16 }}>
                    PAYMENT INV NO: <span style={bolderText}>NONE</span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={4} style={{ ...leftBordered, fontSize: 16 }}>
                    CBM: <span style={bolderText}>{data.cbm}</span>
                  </TableCell>
                  <TableCell align="left" colSpan={4} style={{ ...leftBordered, fontSize: 16 }}>
                    TOTAL AMOUNT: <span style={{ ...bolderText, fontSize: 22 }}>{currencyFormatter(data.total_amount, 'USD')}</span>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Table sx={{ minWidth: 700, padding: 100 }} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align="center" style={{ ...leftBordered, minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, idx) => (
                  <TableRow key={idx}>
                    {row.pkgs && (
                      <TableCell style={leftBordered} rowSpan={row.pkgs.span} align="center">
                        {row.pkgs.value}
                      </TableCell>
                    )}
                    {row.bn && (
                      <TableCell style={leftBordered} rowSpan={row.bn.span} align="center">
                        {row.bn.value}
                      </TableCell>
                    )}
                    <TableCell style={leftBordered} align="center">
                      {row.qty}
                    </TableCell>
                    <TableCell style={leftBordered} align="center">
                      {row.unit}
                    </TableCell>
                    <TableCell style={leftBordered} align="center">
                      {row.description}
                    </TableCell>
                    <TableCell style={leftBordered} align="center">
                      {row.receipt}
                    </TableCell>
                    {row.cbm && (
                      <TableCell style={leftBordered} rowSpan={row.cbm.span} align="center">
                        {row.cbm.value}
                      </TableCell>
                    )}
                    {row.amount && (
                      <TableCell style={leftBordered} rowSpan={row.amount.span} align="center">
                        {currencyFormatter(row.amount.value, 'USD')}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Paper>
    </MainCard>
  );
}

export default CustomerPackingList;
