import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import { Transition } from '../../../../ui-component';
import { Button, DialogContent, DialogTitle, Grid } from '@mui/material';
import { IconChevronLeft, IconPackages, IconReceipt } from '@tabler/icons-react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { containerPackingListRequest } from '../../../../utils/api';
import appNotify from '../../../../utils/libs/appNotify';
import { Result, Spin } from 'antd';
import Receipt from './Receipt';

function ReceiptTab({ receipt }) {
  const [cpl, setCPL] = useState(null);
  const [loading, setLoading] = useState(true);

  // function getCPL() {
  //   containerPackingListRequest(receipt.id)
  //     .then((res) => {
  //       console.log('CPL Res: ', res);
  //       setCPL(res.data);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log('Err: ', err);
  //       appNotify.error('Failed to load container Packing List');
  //     });
  // }
  //
  // useEffect(() => {
  //   getCPL();
  // }, []);
  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '70vh' }}>
        <Spin size="large" tip="Loading Packing List" />
      </Grid>
    );
  }

  return cpl ? <Receipt width="100%" data={cpl} /> : <Result status="info" title="No received goods" />;
}

ReceiptTab.propTypes = {
  receipt: PropTypes.any
};
function ReceiptViewModal({ open, onClose, receipt = null }) {
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();

  console.log(receipt);
  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  function handleClose() {
    onClose();
  }

  return (
    <Dialog TransitionComponent={Transition} fullScreen fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle style={{ fontWeight: 'bold', fontSize: 20 }}>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item>
            <Grid container>
              <Grid item>
                <Button
                  startIcon={<IconChevronLeft />}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {t('common.go_back')}
                </Button>
              </Grid>
              <Grid item>{receipt.supplier_receipt_no}</Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Tabs value={tab} onChange={handleChange} centered>
              <Tab value={0} icon={<IconReceipt />} iconPosition="start" label={t('goods.receipt')} />
              <Tab value={1} icon={<IconPackages />} iconPosition="start" label={t('goods.title')} />
            </Tabs>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center">
          <Grid item xs={11}>
            {/* {tab === 0 && <ReceiptTab receipt={receipt} />} */}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

ReceiptViewModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  receipt: PropTypes.any
};
export default ReceiptViewModal;
