import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AnimateButton from './extended/AnimateButton';
import { TZSNumericCustom } from './index';
import { currencyFormatter } from '../utils/libs';
import PropTypes from 'prop-types';

function ExchangeRateForm({ loading, onSubmit }) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  const formRef = useRef();
  return (
    <>
      <Formik
        initialValues={{
          rate: ''
        }}
        innerRef={formRef}
        validationSchema={Yup.object().shape({
          rate: Yup.string().max(255).required('Amount is required')
        })}
        onSubmit={async (values) => {
          if (checked) onSubmit(values);
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="bootstrap">Exchange Rate</InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="text"
                    value={values.rate}
                    name="rate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    placeholder="eg. TZS 2,550"
                    inputComponent={TZSNumericCustom}
                    startAdornment={
                      <InputAdornment style={{ padding: 0 }} position="start">
                        {currencyFormatter(1, 'USD')} =
                      </InputAdornment>
                    }
                    error={Boolean(touched.supplier_receipt_no && errors.supplier_receipt_no)}
                  />
                  {touched.rate && errors.rate && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.rate}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <Grid container mt={3} justifyContent="center">
        <Grid item>
          <FormGroup>
            <FormControlLabel onChange={(e, c) => setChecked(c)} checked={checked} control={<Checkbox />} label={t('common.tick_sure')} />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" mt={0} mg={3}>
        <Grid item xs={3}>
          <AnimateButton>
            <LoadingButton
              disableElevation
              loading={loading}
              disabled={loading || !checked}
              fullWidth
              size="large"
              onClick={() => {
                formRef.current.handleSubmit();
              }}
              variant="contained"
              color="primary"
            >
              {t('common.submit')}
            </LoadingButton>
          </AnimateButton>
        </Grid>
      </Grid>
    </>
  );
}

ExchangeRateForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default ExchangeRateForm;
