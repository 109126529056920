import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { useLanguageCode } from '../../../../utils/libs/hooks';

export default function LanguageList() {
  const { t, i18n } = useTranslation();
  const languageCode = useLanguageCode();
  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <nav aria-label="secondary mailbox folders">
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => i18n.changeLanguage('en')}>
              <ListItemText primary="English (UK)" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => i18n.changeLanguage('zh')}>
              <ListItemText primary="中国人 (Chinese)" />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
    </Box>
  );
}
