import { listContainersRequest, searchContainersRequest } from '../../api';
import { useEffect, useState } from 'react';
import appNotify from '../appNotify';

export default function useContainers(init = false, initialParams = {}) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [containers, setContainers] = useState([]);
  const [next, setNext] = useState(null);
  const [params, setParams] = useState({
    search: null,
    page: null,
    ...initialParams
  });
  const [containerOptions, setContainerOptions] = useState([]);
  const [containerOptionsLoading, setContainerOptionsLoading] = useState(false);
  function listContainers() {
    setPageLoading(true);
    listContainersRequest(params)
      .then((res) => {
        setContainers(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch(() => {
        appNotify.error('Failed to load containers');
        setContainers([]);
      });
  }

  function loadContainers() {
    setContainerOptionsLoading(true);
    searchContainersRequest(params)
      .then((res) => {
        setContainerOptions(res.results);
        setContainerOptionsLoading(false);
      })
      .catch(() => {
        appNotify.error('Failed to search containers');
        setContainerOptions([]);
      });
  }

  useEffect(() => {
    if (init) listContainers();
    else if (params.search && params.search.length > 0) {
      loadContainers();
    }
  }, [params]);

  return {
    pageLoading,
    setContainers,
    next,
    containers,
    count,
    listContainers,
    containerOptionsLoading,
    setParams,
    containerOptions
  };
}
