import React from 'react';
import { Collapse, Descriptions } from 'antd';
import { renderConsignmentStatus, renderDateTime } from '../../../../utils/libs';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function GoodConsignedCollapse({ goodCons }) {
  const onChange = (key) => {
    console.log(key);
  };
  const { t } = useTranslation();
  const items = goodCons.map((goodCon, idx) => ({
    key: idx,
    label: `${goodCon.consignment.tracking_number}`,
    children: (
      <Descriptions
        // title={`${goodCon.consignment.tracking_number}`}
        layout="vertical"
        bordered
        items={[
          {
            key: '1',
            label: t('goods.consigned_at'),
            children: renderDateTime(goodCon.consignment.created_at)
          },
          {
            key: '2',
            label: t('consignments.tracking_no'),
            children: goodCon.consignment.tracking_number
          },
          {
            key: '3',
            label: t('consignments.label'),
            children: goodCon.consignment.label
          },
          {
            key: '4',
            label: t('consignments.pkgs'),
            children: goodCon.pkgs
          },
          {
            key: '5',
            label: t('consignments.quantity'),
            children: goodCon.quantity
          },
          {
            key: '7',
            label: t('consignments.cbm'),
            children: goodCon.consignment.cbm
          },
          {
            key: '8',
            label: t('containers.container_no'),
            children: goodCon.consignment.container ? goodCon.consignment.container.container_no : 'NOT LOADED'
          },
          {
            key: '9',
            label: t('containers.ship_line'),
            children: goodCon.consignment.container ? goodCon.consignment.container.shipping_line : 'NOT LOADED'
          },
          {
            key: '10',
            label: t('containers.shipping_date'),
            children: goodCon.consignment.container ? goodCon.consignment.container.shipping_date : 'NOT LOADED'
          },
          {
            key: '10',
            label: t('containers.arrival_date'),
            children: goodCon.consignment.container ? goodCon.consignment.container.arrival_date : 'NOT LOADED'
          },
          {
            key: '10',
            label: t('containers.status'),
            children: renderConsignmentStatus(goodCon.consignment.delivery_status)
          }
        ]}
      />
    )
  }));
  return <Collapse items={items} defaultActiveKey={[0]} onChange={onChange} />;
}

GoodConsignedCollapse.propTypes = {
  goodCons: PropTypes.array
};
export default GoodConsignedCollapse;
