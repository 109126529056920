import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Grid, Toolbar, useMediaQuery } from '@mui/material';
import Breadcrumbs from '../../ui-component/extended/Breadcrumbs';
import Header from './Header';
import navigation from 'menu-items';
import { drawerWidth } from 'utils/libs/constants';
import { SET_MENU } from 'store/reducers/actions';
import { IconChevronRight } from '@tabler/icons-react';
import { CurrencyRateProvider } from '../../ui-component';
import { Spin } from 'antd';
import AppInitializer from '../../utils/libs/appInit';
import PermissionProvider from '../../pages/users-roles-manager/components/PermissionHandler';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
      marginRight: '10px'
    }
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px'
    }
  })
}));

// ==============================|| MAIN LAYOUT ||============================== //

function MainLayout() {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const initializing = useSelector((state) => state.app.initializing);
  const role = useSelector((state) => state.auth.user.role ?? state.auth.user.is_superuser);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  useEffect(() => {
    const appInitializer = new AppInitializer(navigate, dispatch, pathname);
    appInitializer.init().then();
  }, []);

  return initializing ? (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Spin size="large" />
    </Grid>
  ) : (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
        {role ? <Outlet /> : <PermissionProvider message="Sorry you have no role assigned, please contact administrato!r" showUnAuthorizedPage withOtherDeps={() => false} />}
      </Main>
      {/* <Customization /> */}
      <CurrencyRateProvider />
    </Box>
  );
}

export default MainLayout;
