import { DialogContent, DialogTitle } from '@mui/material';
import { addDiscountRequest } from '../../utils/api';
import appNotify from '../../utils/libs/appNotify';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import PerformDiscountForm from './components/PerformDiscountForm';
import PropTypes from 'prop-types';

function PerformDiscount({ open, setOpen, onAddDiscount = () => {}, invoice = null }) {
  const [loading, setLoading] = useState(false);

  function addDiscount(values) {
    setLoading(true);
    addDiscountRequest({ ...values, bill: invoice.id })
      .then((res) => {
        console.log('Res: ', res);
        appNotify.success('Discount added successfully');
        setOpen(false);
        onAddDiscount();
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to add discount');
        setLoading(false);
      });
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle style={{ fontWeight: 'bold' }}>Adding Discount</DialogTitle>
      <DialogContent>
        <PerformDiscountForm currentAmount={invoice ? invoice.amount : 0} loading={loading} onSubmit={(values) => addDiscount(values)} />
      </DialogContent>
    </Dialog>
  );
}

PerformDiscount.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.bool,
  invoice: PropTypes.any,
  onAddDiscount: PropTypes.func
};

export default PerformDiscount;
