import { getUserFromTokenRequest } from '../api';
import { setUser } from '../../store/reducers/auth';
import { setInitializing } from '../../store/reducers/app';

class AppInitializer {
  constructor(navigate, dispatch, pathname) {
    this.navigate = navigate;
    this.dispatch = dispatch;
    this.pathname = pathname;
  }

  async init() {
    const user = await this.getUser();
    // const system_config = await this.getSystemConfig();
    if (user) {
      this.dispatch(setInitializing(false));
      // const appConfig = await this.getSystemConfig();
      // if (appConfig) {
      //     console.log('Inside app Config');
    }
    // }
  }

  getUser() {
    return getUserFromTokenRequest()
      .then((user) => {
        this.dispatch(setUser(user));
        return true;
      })
      .catch((err) => false);
  }
  // getSystemConfig() {
  //  return getSystemConfigRequest()
  //   .then((res) => {
  //    this.dispatches(setServiceAvailability(res));
  //    return true;
  //   })
  //   .catch((err) => {
  //    return false;
  //   });
  // }
}

export default AppInitializer;
