import { Descriptions } from 'antd';
import { Chip, Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../../ui-component/cards/MainCard';
import PropTypes from 'prop-types';
import useCargos from '../../../../utils/libs/hooks/use-cargos';
import ConsignmentTable from '../../../consignments/components/ConsignmentTable';
import { renderContainerStatus, renderDateTime } from '../../../../utils/libs';

function CustomerCargos({ customerID }) {
  const { t } = useTranslation();
  const { cargos, setParams, count, next, pageLoading } = useCargos({ c: customerID });
  const columns = [
    // { id: 'created_at_', label: 'Created At' },
    { id: 'name', label: 'Item' },
    { id: 'label', label: 'Label' },
    // { id: 'customer_full_name', label: 'Customer' },
    { id: 'tracking_number', label: 'Tracking No' },
    { id: 'pkgs', label: 'PKGS' },
    { id: 'cbm', label: 'CBM' },
    { id: 'shipping_charge', label: 'Shipping Cost' }
    // { id: 'delivery_status', label: 'Status' }
    // { id: 'actions', label: 'Actions' }
  ];

  function formatToItems(cargo) {
    return [
      {
        key: '1',
        label: 'Container Number',
        span: 1,
        children: cargo.container ? cargo.container.container_no : 'Not set'
      },
      {
        key: '2',
        label: 'Shipped Date',
        span: 1,
        children: cargo.container ? cargo.container.shipping_date : 'Not set'
      },
      {
        key: '3',
        label: 'Arrival Date',
        span: 1,
        children: cargo.container ? cargo.container.arrival_date : 'Not set'
      },
      {
        key: '4',
        label: 'Invoice',
        span: 1,
        children: cargo.inv ? `#${cargo.inv.id}` : 'Not set'
      },
      {
        key: '5',
        label: 'Status',
        span: 2,
        children: cargo.container ? (
          renderContainerStatus(cargo.container.shipping_status)
        ) : (
          <Chip label="Pending" variant="outlined" size="small" />
        )
      },
      {
        key: '6',
        label: 'Consignments',
        span: 3,
        children: (
          <ConsignmentTable
            columns={columns}
            pageLoading={false}
            next={null}
            showPaginator={false}
            consignments={cargo.consignments.map((c) => ({ ...c, created_at_: renderDateTime(c.created_at) }))}
            count={cargo.consignments.length}
          />
        )
      }
    ];
  }
  return (
    <MainCard
      title={
        <Grid container spacing={3} alignItems="center">
          <Grid item>List of Customer Cargos</Grid>
        </Grid>
      }
    >
      {cargos.map((cargo, idx) => (
        <>
          <Grid container alignItems="center" justifyContent="center" mb={4} mt={1}>
            <Grid item xs={11}>
              <Descriptions
                // title={`Discount on ${new Date(cargo.created_at).toLocaleDateString('en-CA')}`}
                layout="vertical"
                size="small"
                bordered
                items={formatToItems(cargo)}
              />
            </Grid>
            {/* <Grid item xs={1}> */}
            {/*   <IconButton> */}
            {/*     <IconTrash /> */}
            {/*   </IconButton> */}
            {/* </Grid> */}
          </Grid>
          <Divider style={{ marginBottom: 30 }} />
        </>
      ))}
    </MainCard>
  );
}

CustomerCargos.propTypes = {
  customerID: PropTypes.string
};
export default CustomerCargos;
